import { Injectable } from "@angular/core";
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList, AngularFireObject ,} from '@angular/fire/database';
import { Router } from '@angular/router';
import { jmyConfig } from 'environments/environment';
import { auth } from 'firebase';

import { map } from 'rxjs/operators';
import { navInterface, RedireccionarInterface } from './interfaces/services';
import { JmyPerfil } from "./admin/JmyPerfil";
import { JmyLogin } from "./admin/JmyLogin";
import { JmyFn } from "./admin/jmyFn";
import { PresenceService } from "./presence.service";
//import { JmyDB } from "./admin/jmyDB";
import { ToastrService } from 'ngx-toastr';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import * as firebase from 'firebase/app';

import 'rxjs/operator/take';

@Injectable({
  providedIn: 'root'
})
export class JmyService {
  messagin = firebase.messaging();
  currentMessage = new BehaviorSubject(null);
  config: navInterface = jmyConfig;
  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    public jmyLogin: JmyLogin,
    public jmyPerfil: JmyPerfil,
    public jmyFn: JmyFn,
    public jmyPresecia: PresenceService,
    public dbRealTime: AngularFireDatabase,
  //  public jmyDB: JmyDB,
    public toastr: ToastrService
  ) { 


  }
  sesion(d: RedireccionarInterface = {
    redireccionar: jmyConfig.app.routes.login
  }) {
    return new Promise((res, rej) => {
      if (this.jmyPerfil.perfil != undefined)
        res(this.jmyPerfil.perfil);
      else
        this.router.navigate([d.redireccionar]);
      rej(this.jmyPerfil.perfil);
    })
  }
  getCurrentUser() {
    return this.afAuth.authState.pipe(map(auth => auth));
  }
  doLogout() {
    return new Promise((o, er) => {
      this.jmyLogin.logOut().catch(r => {
        er(true);
      }).then(r => {
        o();
      })
    });
  }
  private cargarPerfil() {
    this.getCurrentUser().subscribe(s => {
      this.jmyPerfil.cargaPerfil(s.providerData[0]);
    })
  }
}

import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = 
[
    {
        id       : 'tecnopack',
        title    : 'Tecnopack',
        hidden    : true,
        modulo : [{
            permiso:1,
            modulo:'tpk_ventas'
        },{
            permiso:1,
            modulo:'tpk_diseno'
        },{
            permiso:1,
            modulo:'tpk_cotizaciones'
        },{
            permiso:1,
            modulo:'tpk_clientes'
        },{
            permiso:1,
            modulo:'tpk_compras'
        },{
            permiso:1,
            modulo:'tpk_asignacion_diseno'
        }
        ],
        translate: 'NAV.TECNOPACK',
        type     : 'group',
        icon     : 'apps',
        children : [
          /*  {
                id       : 'dashboards',
                title    : 'Dashboards',
                translate: 'NAV.DASHBOARDS',
                type     : 'collapsable',
                icon     : 'dashboard',
                children : [
                    {
                        id   : 'analytics',
                        title: 'Analytics',
                        type : 'item',
                        url  : '/apps/dashboards/analytics' 
                    },
                    {
                        id   : 'project',
                        title: 'Project',
                        type : 'item',
                        url  : '/apps/dashboards/project'
                    }
                ]
            },        */   
           
            {
                id   : 'diseno_referencias',
                title: 'Diseño referencias ',
                icon   : 'assignment_turned_in',
                modulo : [{
                    permiso:1,
                    modulo:'tpk_diseno'
                }],
                type : 'item',
                url  : '/tpk/referencia' 
            },
            {
                id   : 'diseno_asignacion',
                title: 'Asignación de diseño',
                icon   : 'assignment_ind',
                modulo : [{
                    permiso:1,
                    modulo:'tpk_asignacion_diseno'
                }],
                type : 'item',
                url  : '/tpk/brief/sinAsignar'
            }, 
            {
                id       : 'ventas',
                title    : 'Ventas',
                modulo : [{
                    permiso:1,
                    modulo:'tpk_ventas'
                }],
                translate: 'NAV.VENTAS',
                type     : 'item',
                icon     : 'assessment',
                url      : '/tpk/brief'
            },  
            {
                id       : 'cotizaciones',
                title    : 'Cotizaciones',
                modulo : [{
                    permiso:1,
                    modulo:'tpk_cotizaciones'
                }],
                translate: 'NAV.COTIZACIONES',
                type     : 'item',
                icon     : 'assessment',
                url      : '/tpk/referencia'
            },
            {
                id       : 'clientes',
                title    : 'Clientes',
                modulo : [{
                    permiso:1,
                    modulo:'tpk_marcas'
                }],
                translate: 'NAV.CLIENTES',
                type     : 'item',
                icon     : 'assessment',
                url      : '/tpk/clientes'
            },
        ]
    },
    {
        id       : 'stormcatcher',
        title    : 'Sistema Almacén',
        hidden    : true,
        modulo : [{
            permiso:1,
            modulo:'sc_almacen'
        },{
            permiso:1,
            modulo:'sc_finanzas'
        },{
            permiso:1,
            modulo:'sc_operaciones'
        },{
            permiso:1,
            modulo:'sc_ventas'
        }
        ],
        //translate: 'NAV.TECNOPACK',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id   : 'sc_almacen',
                title: 'Almacén',
                modulo : [{
                    permiso:1,
                    modulo:'sc_almacen'
                }],
                type : 'collapsable',
                icon   : 'widgets',
                children:[ {
                    id   : 'sc_almacen_productos',
                    title: 'Artículos',
                    modulo : [{
                        permiso:1,
                        modulo:'sc_almacen'
                    }],
                    type : 'item',
                    icon   : 'unarchive',
                    url  : '/apps/st/almacen'
                },{
                    id   : 'sc_almacen_entradasalida',
                    title: 'Entradas y salidas',
                    modulo : [{
                        permiso:1,
                        modulo:'sc_almacen'
                    }],
                    type : 'item',
                    icon   : 'compare_arrows',
                    url  : '/apps/st/almacen/entradasalida'
                },
                
                {
                    id   : 'sc_almacen_almacenes',
                    title: 'Almacenes',
                    modulo : [{
                        permiso:1,
                        modulo:'sc_almacen'
                    }],
                    type : 'item',
                    icon : 'unarchive',
                    url  : '/apps/st/almacenes'
                },
                {
                    id   : 'sc_almacen_almacenes',
                    title: 'Proveedores',
                    modulo : [{
                        permiso:1,
                        modulo:'sc_almacen'
                    }],
                    type : 'item',
                    icon : 'store',
                    url  : '/apps/st/proveedores'                
                },
                {
                    id   : 'sc_almacen_almacenes',
                    title: 'Locación de almacén',
                    modulo : [{
                        permiso:1,
                        modulo:'sc_almacen'
                    }],
                    type : 'item',
                    icon : 'dashboard',
                    url  : '/apps/st/locaciones'
                }
            ]
            },
            {
                id   : 'sc_finanzas',
                title: 'Finanzas',
                modulo : [{
                    permiso:1,
                    modulo:'sc_finanzas'
                }],
                type : 'item',
                icon   : 'show_chart',
                url  : '/404'
            }, 
            {
                id       : 'sc_operaciones',
                title    : 'Operaciones',
                modulo : [{
                    permiso:1,
                    modulo:'sc_operaciones'
                }],
                type     : 'item',
                icon     : 'rv_hookup',
                url      : '/404'
            },  
            {
                id       : 'sc_ventas',
                title    : 'Ventas',
                modulo : [{
                    permiso:1,
                    modulo:'sc_ventas'
                }],
                type     : 'item',
                icon     : 'attach_money',
                url      : '/404'
            }
        ]
    },
    {
        id       : 'almacen',
        title    : 'Almacen',
        modulo : [{
            permiso:1,
            modulo:'almacen'
        }],
        translate: 'NAV.APPS',
        type     : 'group',
        icon     : 'local_shipping',
        children : [
       
            {
                id       : 'almacen-productos',
                title    : 'Productos',
                hidden    : true,
                modulo : [{
                    permiso:1,
                    modulo:'almacen'
                }],
                translate: 'NAV.ALMACEN',
                type     : 'item',
                icon     : 'archive',
                url      : '/almacen/productos',
               /* badge    : {
                    title: '13',
                    bg   : '#09d261',
                    fg   : '#FFFFFF'
                }*/
            }]
        }, 
        {
        id       : 'apps',
        title    : 'APPS',
        modulo : [{
            permiso:1,
            modulo:'perfil'
        },{
            permiso:1,
            modulo:'appsbeta'
        },{
            permiso:1,
            modulo:'desarrollo'
        }],
        translate: 'NAV.APPS',
        type     : 'group',
        icon     : 'apps',
        children : [
        /*    {
                id       : 'dashboards',
                title    : 'Dashboards',
                modulo : [{
                    permiso:1,
                    modulo:'perfil'
                }],
                translate: 'NAV.DASHBOARDS',
                type     : 'collapsable',
                icon     : 'dashboard',
                children : [
                    {
                        id   : 'analytics',
                        title: 'Analytics',
                        modulo : [{
                            permiso:1,
                            modulo:'perfil'
                        }],
                        type : 'item',
                        url  : '/apps/dashboards/analytics'
                    },
                    {
                        id   : 'project',
                        title: 'Project',
                        modulo : [{
                            permiso:1,
                            modulo:'perfil'
                        }],
                        type : 'item',
                        url  : '/apps/dashboards/project'
                    }
                ]
            },*/
         /*   {
                id       : 'calendar',
                title    : 'Calendar',
                modulo : [{
                    permiso:1,
                    modulo:'perfil'
                }],
                translate: 'NAV.CALENDAR',
                type     : 'item',
                icon     : 'today',
                url      : '/apps/calendar'
            },*/
          /*  {
                id       : 'academy',
                title    : 'Academy',
                modulo : [{
                    permiso:1,
                    modulo:'perfil'
                }],
                translate: 'NAV.ACADEMY',
                type     : 'item',
                icon     : 'school',
                url      : '/apps/academy'
            },*/
           
          /*  {
                id       : 'to-do',
                title    : 'To-Do',
                translate: 'NAV.TODO',
                type     : 'item',
                icon     : 'check_box',
                url      : '/apps/todo',
                badge    : {
                    title: '3',
                    bg   : '#FF6F00',
                    fg   : '#FFFFFF'
                }
            },*/
            {
                id       : 'jmydashboard',
                title    : 'Inicio',
                hidden    : true,
                modulo : [{
                    permiso:1,
                    modulo:'perfil'
                }],
                translate: 'NAV.INICIO',
                type     : 'item',
                icon     : 'home',
                url      : '/apps/dashboards/jmydashboard',
             /*   badge    : {
                    title: '13',
                    bg   : '#09d261',
                    fg   : '#FFFFFF'
                }*/
            },    
            {
                id       : 'chat',
                title    : 'Chat',
                hidden    : true,
                modulo : [{
                    permiso:1,
                    modulo:'perfil'
                }],
                translate: 'NAV.CHAT',
                type     : 'item',
                icon     : 'chat',
                url      : '/apps/chat',
             /*   badge    : {
                    title: '13',
                    bg   : '#09d261',
                    fg   : '#FFFFFF'
                }*/
            },    
            {
                id       : 'contacts',
                title    : 'Contactos',
                hidden    : true,
                modulo : [{
                    permiso:1,
                    modulo:'perfil'
                }],
                translate: 'NAV.CONTACTS',
                type     : 'item',
                icon     : 'account_box',
                url      : '/apps/contacts'
            },
            {
                id       : 'prospectos_y_clientes',
                title    : 'Prospectos y clientes',
                hidden    : true,
                modulo : [{
                    permiso:1,
                    modulo:'perfil'
                }],
                translate: 'NAV.CONTACTS',
                type     : 'item',
                icon     : 'contacts',
                url      : '/apps/ccs/directorio'
            },
            {
                id       : 'scrumboard',
                title    : 'Tablero Scrum',
                hidden    : true,
                modulo : [{
                    permiso:1,
                    modulo:'scrumboard'
                }],
                translate: 'NAV.SCRUMBOARD',
                type     : 'item',
                icon     : 'assessment',
                url      : '/apps/scrumboard'
            },
            {
                id       : 'seminuevos',
                title    : 'Seminuevos',
                hidden    : true,
                modulo : [{
                    permiso:1,
                    modulo:'auto_inventario'
                },{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                translate: 'NAV.SEMINUEVOS',
                type     : 'collapsable',
                icon     : 'directions_car',
                children : [
                    {
                        id   : 'unidades',
                        title: 'Unidades',
                        translate: 'NAV.SEMINUEVOSUNIDADES',
                        modulo : [{
                            permiso:1,
                            modulo:'auto_inventario'
                        },{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type : 'item',
                        url  : '/seminuevos/unidades/'
                    }
                ]
            },
            {
                id       : 'almacen',
                title    : 'Almacén',
                hidden    : true,
                modulo : [{
                    permiso:1,
                    modulo:'appsbeta'
                },{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                translate: 'NAV.ALMACEN',
                type     : 'collapsable',
                icon     : 'local_grocery_store',
                children : [
                    {
                        id   : 'productos',
                        title: 'Productos',
                        translate: 'NAV.ALMACENPRODUCTOS',
                        modulo : [{
                            permiso:1,
                            modulo:'almacen'
                        },{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type : 'item',
                        url  : '/almacen/productos/'
                    }
                ]
            },
            {
                id       : 'encuestas',
                title    : 'Encuestas',
                hidden    : true,
                modulo : [{
                    permiso:1,
                    modulo:'encuestas'
                },{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                translate: 'NAV.ENCUESTAS',
                type     : 'collapsable',
                icon     : 'assignment_late',
                children : [
                    {
                        id   : 'encuestasDisponibles',
                        title: 'Encuestas disponibles',
                        icon     : 'assignment',
                        translate: 'NAV.ENCUENSTASDISPONIBLES',
                        modulo : [{
                            permiso:1,
                            modulo:'encuestas'
                        },{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type : 'item',
                        url  : '/apps/encuestas/encuestas/'
                    },
                    {
                        id   : 'encuestasLista',
                        title: 'Encuestas realizadas',
                        icon     : 'assignment_turned_in',
                        translate: 'NAV.ENCUENSTASREALIZADAS',
                        modulo : [{
                            permiso:1,
                            modulo:'encuestas'
                        },{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type : 'item',
                        url  : '/apps/encuestas/resultados/'
                    }
                ]
            }, 
            {
                id       : 'cursos',
                title    : 'Cursos',
                hidden    : true,
                modulo : [{
                    permiso:1,
                    modulo:'cursos'
                },{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                translate: 'NAV.ENCUESTAS',
                type     : 'collapsable',
                icon     : 'assignment_late',
                children : [
                    {
                        id   : 'encuestasDisponibles',
                        title: 'Cursos disponibles',
                        icon     : 'assignment',
                        translate: 'NAV.CURSOSDISPONIBLES',
                        modulo : [{
                            permiso:1,
                            modulo:'cursos'
                        },{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type : 'item',
                        url  : '/apps/encuestas/encuestas/'
                    },
                    {
                        id   : 'encuestasLista',
                        title: 'Cursos terminados',
                        icon     : 'assignment_turned_in',
                        translate: 'NAV.CURSOSTERMINADOS',
                        modulo : [{
                            permiso:1,
                            modulo:'cursos'
                        },{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type : 'item',
                        url  : '/apps/encuestas/resultados/'
                    },
                    {
                        id   : 'encuestasLista',
                        title: 'Evaluaciones',
                        icon     : 'assignment_turned_in',
                        translate: 'NAV.CURSOSEVALUACIONES',
                        modulo : [{
                            permiso:1,
                            modulo:'cursos'
                        },{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type : 'item',
                        url  : '/apps/encuestas/resultados/'
                    }
                ]
            } 
        ]
    },
    {
        id       : 'apps_ventas',
        title    : 'Ventas',
        modulo : [{
            permiso:1,
            modulo:'perfil'
        },{
            permiso:1,
            modulo:'scrumboard'
        },{
            permiso:1,
            modulo:'desarrollo'
        }],
        translate: 'NAV.APPS',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'prospectos_y_clientes',
                title    : 'Prospectos y clientes',
                modulo : [{
                    permiso:1,
                    modulo:'perfil'
                }],
                translate: 'NAV.PROSPECTOS_Y_CLIENTES',
                type     : 'collapsable',
                icon     : 'contacts',
                children : [
                    {
                        id   : 'prospectos_y_clientes_lista',
                        title: 'Directorio',
                        translate: 'NAV.PROSPECTOS_Y_CLIENTES_DIRECTORIO',
                        modulo : [{
                            permiso:1,
                            modulo:'perfil'
                        }],
                        type : 'item',
                        url  : '/apps/ccs/directorio'
                    },
                    {
                        id   : 'prospectos_y_clientes_calendario',
                        title: 'Calendario',
                        translate: 'NAV.PROSPECTOS_Y_CLIENTES_CALENDARIO',
                        modulo : [{
                            permiso:1,
                            modulo:'perfil'
                        }],
                        type : 'item',
                        url  : '/apps/ccs/directorio/calendario'
                    }
                ]
            },            
            {
                id       : 'seguimiento_proyectos',
                title    : 'Proyectos',
                modulo : [{
                    permiso:1,
                    modulo:'perfil'
                }],
                translate: 'NAV.SEGUIMIENTO_PROYECTOS',
                type     : 'collapsable',
                icon     : 'assignment',
                children : [
                    {
                        id   : 'seguimiento_proyectos_lista',
                        title: 'Proyectos',
                        translate: 'NAV.SEGUIMIENTO_PROYECTOS_LISTA',
                        modulo : [{
                            permiso:1,
                            modulo:'perfil'
                        }],
                        type : 'item',
                        url  : '/apps/ccs/proyectos'
                    }
                ]
            },
            
        ]
    },
    {
        id       : 'applications',
        title    : 'APPS ALPHA',
        hidden    : true,
        modulo : [{
            permiso:1,
            modulo:'desarrollo'
        }],
        translate: 'NAV.APPLICATIONSALPHA',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'dashboards',
                title    : 'Dashboards',
                modulo : [{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                translate: 'NAV.DASHBOARDS',
                type     : 'collapsable',
                icon     : 'dashboard',
                children : [
                    {
                        id   : 'analytics',
                        title: 'Analytics',
                        modulo : [{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type : 'item',
                        url  : '/apps/dashboards/analytics'
                    },
                    {
                        id   : 'project',
                        title: 'Project',
                        modulo : [{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type : 'item',
                        url  : '/apps/dashboards/project'
                    }
                ]
            },
            {
                id       : 'calendar',
                title    : 'Calendar',
                modulo : [{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                translate: 'NAV.CALENDAR',
                type     : 'item',
                icon     : 'today',
                url      : '/apps/calendar'
            },
            {
                id       : 'e-commerce',
                title    : 'E-Commerce',
                modulo : [{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                translate: 'NAV.ECOMMERCE',
                type     : 'collapsable',
                icon     : 'shopping_cart',
                children : [
                    {
                        id        : 'products',
                        title     : 'Products',
                        modulo : [{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type      : 'item',
                        url       : '/apps/e-commerce/products',
                        exactMatch: true
                    },
                    {
                        id        : 'productDetail',
                        title     : 'Product Detail',
                        modulo : [{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type      : 'item',
                        url       : '/apps/e-commerce/products/1/printed-dress',
                        exactMatch: true
                    },
                    {
                        id        : 'orders',
                        title     : 'Orders',
                        modulo : [{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type      : 'item',
                        url       : '/apps/e-commerce/orders',
                        exactMatch: true
                    },
                    {
                        id        : 'orderDetail',
                        title     : 'Order Detail',
                        modulo : [{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type      : 'item',
                        url       : '/apps/e-commerce/orders/1',
                        exactMatch: true
                    }
                ]
            },
            {
                id       : 'academy',
                title    : 'Academy',
                modulo : [{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                translate: 'NAV.ACADEMY',
                type     : 'item',
                icon     : 'school',
                url      : '/apps/academy'
            },
          /*  {
                id       : 'mail',
                title    : 'Mail',
                modulo : [{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                translate: 'NAV.MAIL.TITLE',
                type     : 'item',
                icon     : 'email',
                url      : '/apps/mail',
                badge    : {
                    title    : '25',
                    translate: 'NAV.MAIL.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            },
            {
                id       : 'mail-ngrx',
                title    : 'Mail Ngrx',
                modulo : [{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                translate: 'NAV.MAIL_NGRX.TITLE',
                type     : 'item',
                icon     : 'email',
                url      : '/apps/mail-ngrx',
                badge    : {
                    title    : '13',
                    translate: 'NAV.MAIL_NGRX.BADGE',
                    bg       : '#EC0C8E',
                    fg       : '#FFFFFF'
                }
            },*/
            {
                id       : 'chat',
                title    : 'Chat',
                modulo : [{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                translate: 'NAV.CHAT',
                type     : 'item',
                icon     : 'chat',
                url      : '/apps/chat',
               /* badge    : {
                    title: '13',
                    bg   : '#09d261',
                    fg   : '#FFFFFF'
                }*/
            },
       /*     {
                id       : 'file-manager',
                title    : 'File Manager',
                modulo : [{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                translate: 'NAV.FILE_MANAGER',
                type     : 'item',
                icon     : 'folder',
                url      : '/apps/file-manager'
            },*/
            {
                id       : 'contacts',
                title    : 'Contactos',
                modulo : [{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                translate: 'NAV.CONTACTS',
                type     : 'item',
                icon     : 'account_box',
                url      : '/apps/contacts'
            },
            {
                id       : 'to-do',
                title    : 'To-Do',
                modulo : [{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                translate: 'NAV.TODO',
                type     : 'item',
                icon     : 'check_box',
                url      : '/apps/todo',
                badge    : {
                    title: '3',
                    bg   : '#FF6F00',
                    fg   : '#FFFFFF'
                }
            }
        ]
    },
    {
        id      : 'pages',
        title   : 'Fijas ALPHA',
        hidden    : true,
        modulo : [{
            permiso:1,
            modulo:'desarrollo'
        }],
        type    : 'group',
        icon    : 'pages',
        children: [           
            {
                id   : 'coming-soon',
                title: 'Coming Soon',
                modulo : [{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                type : 'item',
                icon : 'alarm',
                url  : '/pages/coming-soon'
            },
            {
                id      : 'errors',
                title   : 'Errors',
                modulo : [{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                type    : 'collapsable',
                icon    : 'error',
                children: [
                    {
                        id   : '404',
                        title: '404',
                        modulo : [{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type : 'item',
                        url  : '/pages/errors/error-404'
                    },
                    {
                        id   : '500',
                        title: '500',
                        modulo : [{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type : 'item',
                        url  : '/pages/errors/error-500'
                    }
                ]
            },
            {
                id      : 'invoice',
                title   : 'Invoice',
                modulo : [{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                type    : 'collapsable',
                icon    : 'receipt',
                children: [
                    {
                        id   : 'modern',
                        title: 'Modern',
                        modulo : [{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type : 'item',
                        url  : '/pages/invoices/modern'
                    },
                    {
                        id   : 'compact',
                        title: 'Compact',
                        modulo : [{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type : 'item',
                        url  : '/pages/invoices/compact'
                    }
                ]
            },
            {
                id   : 'maintenance',
                title: 'Maintenance',
                modulo : [{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                type : 'item',
                icon : 'build',
                url  : '/pages/maintenance'
            },
            {
                id      : 'pricing',
                title   : 'Pricing',
                modulo : [{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                type    : 'collapsable',
                icon    : 'attach_money',
                children: [
                    {
                        id   : 'style-1',
                        title: 'Style 1',
                        modulo : [{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type : 'item',
                        url  : '/pages/pricing/style-1'
                    },
                    {
                        id   : 'style-2',
                        title: 'Style 2',
                        modulo : [{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type : 'item',
                        url  : '/pages/pricing/style-2'
                    },
                    {
                        id   : 'style-3',
                        title: 'Style 3',
                        modulo : [{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type : 'item',
                        url  : '/pages/pricing/style-3'
                    }
                ]
            },
           
            {
                id      : 'search',
                title   : 'Search',
                modulo : [{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                type    : 'collapsable',
                icon    : 'search',
                children: [
                    {
                        id   : 'search-classic',
                        title: 'Classic',
                        modulo : [{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type : 'item',
                        url  : '/pages/search/classic'
                    },
                    {
                        id   : 'search-modern',
                        title: 'Modern',
                        modulo : [{
                            permiso:1,
                            modulo:'desarrollo'
                        }],
                        type : 'item',
                        url  : '/pages/search/modern'
                    }
                ]
            },
            {
                id   : 'faq',
                title: 'Faq',
                modulo : [{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                type : 'item',
                icon : 'help',
                url  : '/pages/faq'
            },
            {
                id   : 'knowledge-base',
                title: 'Knowledge Base',
                modulo : [{
                    permiso:1,
                    modulo:'desarrollo'
                }],
                type : 'item',
                icon : 'import_contacts',
                url  : '/pages/knowledge-base'
            }
        ]
    }
];

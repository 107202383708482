import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { jmyConfig } from 'environments/environment';
import { Observable,BehaviorSubject, from , Subject} from 'rxjs';
import { navInterface, 
    NotificarInterface,
    configFnInterface,
    ActividadesInterface, 
    infoPerfilInterface,
    perfilAcercaInterface, 
    TimelinePostInterface,
    TimelinePostConfigInterface,
    TimelinePostCommentInterface,
    TimelinePostActionsUserInterface   
    } from '../interfaces/services';
import { States } from '../admin/states';
import { JmyPerfil } from './JmyPerfil';
import { HttpClient } from '@angular/common/http';
import { navigation } from 'app/navigation/navigation';
import { log } from 'util';
import { Router }  from '@angular/router'
import { MatSnackBar } from '@angular/material/snack-bar';
import { group } from '@angular/animations';


@Injectable()
export class JmyS {
    public contadores:BehaviorSubject<object>;
    public notificacionesAlert:BehaviorSubject<object>;
    private config: navInterface = jmyConfig;
    private usersRef: AngularFireList<any>; // Reference to users list, Its an Observable
    private userRef: AngularFireObject<any>; // Reference to user object, Its an Observable too
    private CNF:{ruta:{q:string}};
    lT:AngularFireList<any>;
    configTimeline:TimelinePostConfigInterface;
    
    timeline: any;
    private fnQTime:any;
    likeUser:object;
    constructor(
        private db: AngularFireDatabase,
        private http: HttpClient,
        private router: Router,
        private jmyPerfil: JmyPerfil,
        ) {
            this.CNF={ruta:{
                q : '/_SyS/'+jmyConfig.app.eid+'/',
                //eid:['']
            }};
            console.log('jmyS CNF',this.CNF);
            this.likeUser={}
            this.configTimeline={ruta:'timeline/salas/privado/'+this.jmyPerfil.perfil.uid+'/'};
    }

    getTimeline(uid:string,d?:TimelinePostConfigInterface): Promise<any[]>
    {
        return new Promise((resolve, reject) => {
            if(d==undefined)
                d=this.configTimeline
            d.ruta=(d.ruta!=undefined)?d.ruta:this.configTimeline.ruta;
           console.log(d);
            this.fnQTime = this.db.list(d.ruta).snapshotChanges()
              .subscribe(r=>{
               let uR:string[]=r.map(c => (c.payload.key));
               let qR:any[]=r.map(c => ({ key: c.payload.key, ...c.payload.val() }));
                this.timeline = qR.map(tT => {
                    let b:boolean=false;
                    let likeUDisplay:TimelinePostActionsUserInterface[]=[];
                    if(tT.likeU!=undefined)
                        Object.keys(tT.likeU).forEach(eL => {
                            let tm = tT.likeU[eL];

                            console.log(tm.state,tm);
                                
                            if(tm.state=="1"){
                                let gT = {
                                    uid:tm.uid,
                                    photoURL:tm.photoURL,
                                    displayName:tm.displayName,
                                    timestamp:tm.timestamp,
                                };
                                console.log(likeUDisplay,gT);
                                likeUDisplay.push(gT) 
                                console.log(likeUDisplay,gT);
                            }
                                
                                //tm.likeUDisplay.push(tm.likeU);

                            if(this.jmyPerfil.perfil.uid==tm.uid){
                               // console.log(tm,tm.uid,this.jmyPerfil.perfil.uid);
                                this.likeUser[tT.key]=(tm.state==="1")?true:false;
                                b=(tm.state==="1")?true:false;
                            }else{
                                b=(!b)?false:b;
                            }
                        });
                    tT.likeUDisplay=likeUDisplay;
                    tT.banderaLike=(b)?'primary':'';
                    //console.log(tT);
                   return tT
                })||[];
                //console.log(this.timeline,this.likeUser);
                //this.timelineOnChanged.next(this.timeline);
                resolve(this.timeline);
                console.log(this.timeline);
                
            })
        });
    }
}
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Contact } from 'app/main/apps/contacts/contact.model';
import { JmyService } from 'app/concomsis/jmy/jmy.service';
import { infoPerfilInterface } from 'app/concomsis/jmy/interfaces/services';
import { jmyConfig } from 'environments/environment';

import { BehaviorSubject } from 'rxjs';
@Component({
    selector     : 'contacts-ecard-dialog',
    templateUrl  : './ecard.component.html',
    styleUrls    : ['./ecard.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ContactsEcardComponent
{
    vista: string;
    contact: Contact;
    contactForm: FormGroup;

    verEcard: boolean;
    verUrl: boolean;
    verBotonWhats: boolean;
    mensajeLink: string;
    urlShare: string;
    telWhatsApp: string;
    telWhatsAppOnChange: BehaviorSubject<string>;
    urlWhatsApp: string;
    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactsContactFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<ContactsEcardComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private jmyService: JmyService
    )
    {
        // Set the defaults
        this.vista = _data.vista;
        this.verUrl=false;
        this.verBotonWhats=false;
        this.mensajeLink='Ver link';
        this.contact = _data.contact;
        this.urlShare='';
        this.urlWhatsApp='';
        this.telWhatsApp='';
        this.telWhatsAppOnChange=new BehaviorSubject(this.telWhatsApp);
        //this.jmyService.jmyPerf
        const p:infoPerfilInterface = this.jmyService.jmyPerfil.perfil;
        this.verEcard = (this.vista=='ecard')?true:false;
        this.urlShare=jmyConfig.app.ecard[0].url+''+p.eCard ;
        this.contact = new Contact({});
        
        
        this.jmyService.dbRealTime.object(this.jmyService.jmyPerfil.rutaPublica+'lista/'+this.jmyService.jmyPerfil.perfil.uid).valueChanges().subscribe(r=>{
            //q.unsubscribe();
            this.urlShare=jmyConfig.app.ecard[0].url+''+p.eCard+'/'+p.eCardHand ;
            this.telWhatsAppOnChange.subscribe(tel=>{
                console.log(tel,tel.length);
                this.verBotonWhats=(tel.length==10)?true:false;
                this.urlWhatsApp='https://api.whatsapp.com/send?phone=521'+tel+'&text='+encodeURI('Hola soy '+this.jmyService.jmyPerfil.perfil.displayName+ '!, da click el enlace para ver mi tarjeta digital '+this.urlShare)
            });
            console.log(this.contact,p,r,this.jmyService.jmyPerfil.perfil,this.jmyService.jmyPerfil.rutaPublica+'lista/'+this.jmyService.jmyPerfil.perfil.uid); 
            this.contact = {...this.contact,
                id:p.uid,
                name:p.displayName,
                avatar:p.photoURL,
                email:p.email,
                phone:p.phoneNumber
            }; 
            this.contactForm = this.createContactForm();
        });
        this.contactForm = this.createContactForm();

    }
    actualizarWhats(e:any){
        this.telWhatsApp=e['target']['value'];
        this.telWhatsAppOnChange.next(this.telWhatsApp);
        console.log(this.telWhatsApp,e);
        
    }
    verLink(){
        this.verUrl=(this.verUrl)?false:true;
        this.mensajeLink=(this.verUrl)?'Ocultar link':'Ver link';
    }
    verVistaECard(){
        this.vista = (this.vista=='ecard')?'':'ecard';
        this.verEcard = (this.vista=='ecard')?true:false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createContactForm(): FormGroup
    {
        return this._formBuilder.group({
            id      : [this.contact.id],
            name    : [this.contact.name],
            lastName: [this.contact.lastName],
            avatar  : [this.contact.avatar],
            nickname: [this.contact.nickname],
            company : [this.contact.company],
            jobTitle: [this.contact.jobTitle],
            email   : [this.contact.email],
            phone   : [this.contact.phone],
            address : [this.contact.address],
            birthday: [{ value: this.contact.birthday, disabled: true }],
            notes   : [this.contact.notes]
        });
    }
}

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';

import { JMYTimeLineService } from 'app/concomsis/jmy/jmy-timeline/jmy-timeline.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { log } from 'util';
import { ToastrService } from 'ngx-toastr';
import { JmyService } from 'app/concomsis/jmy/jmy.service';
import { infoPerfilInterface, TimelinePostConfigInterface } from 'app/concomsis/jmy/interfaces/services';
declare var $: any;
@Component({
    selector     : 'app-jmy-timeline',
    templateUrl  : './jmy-timeline.component.html',
    styleUrls    : ['./jmy-timeline.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class JmyTimelineComponent implements OnInit, OnDestroy{
    
    timeline: {
        actividad:object[],
        posts:object[]
    };
    mensaje:string;
    private me:boolean;
    perfil:infoPerfilInterface;
    // Private
    private _unsubscribeAll: Subject<any>;
    private productionorder:any;
    private qtd:object;
    comentarios:string;
    ruta:string=null;
    config:TimelinePostConfigInterface;
    div:{
        todo:boolean
    }={
        todo:false
    };
    /**
     * Constructor
     *
     * @param {ProfileService} _JMYTimeLineService
     */
    constructor(
        private _JMYTimeLineService: JMYTimeLineService,
        private toast: ToastrService,
        private jmyService: JmyService,
    )
    {        
        this.perfil =this.jmyService.jmyPerfil.perfil;
        this.me=false;
        this.timeline={actividad:[],posts:[]}
        this.mensaje='';
        this.comentarios='';
        this.qtd={};

        this._unsubscribeAll = new Subject();
        this._JMYTimeLineService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(r=>{
            this.config=r;
            console.log(this.config);
            this.div.todo=(this.config.ruta!=undefined)?(this.config.ruta!='')?true:false:false;
        })
        this._JMYTimeLineService.timelineOnChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(gT=>{
            //console.log(gT, this.config);
            this._JMYTimeLineService.timelineOnChanged
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(timeline => {
                    this.div.todo=(timeline!=undefined)?true:false;
                    this.timeline.posts=(timeline!=undefined)?timeline.map(r=>{
                        let cO=[];
                        if(r.comments!=undefined)
                            Object.keys(r.comments).forEach(rC=>{
                                cO.push(r.comments[rC]);
                            })
                        r.comments = cO;
                        $(document).ready(function() {
                            $('.escoderComentarios').hide();
                        });
                        return {...r}
                }).reverse():[];
            });
        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    timelimePostComment(key:string,l:any){
        console.log(this.qtd[key] ,this.comentarios,key,l);
        
        this._JMYTimeLineService.postCommentTimeline(this.qtd[key],key,{ruta:this.config.ruta}).then(r=>{
            console.log(r);
        
        this.comentarios='';
            
        })
        //const m=document.getElementById('post_'+key).nodeValue;
        //console.log(key,this['post_'+key],m,this.productionorder);
        
    }
    timelimePost(){
        console.log('lol',this.mensaje,this.config);
        if(this.mensaje!='')
            this._JMYTimeLineService.postTimeline({
                message:this.mensaje,
                config:this.config
            }).then(r=>{
                this.mensaje='';
            })
        else  
            this.toast.warning('','Ingresa algún comentario ');
    }
    /**
     * On init
     */
    onKey(value: any,d:any) {
        this.qtd[d]=value.target.value;
    }
    infoEnterado(displayName:string='NOMBRE',timestamp:string=''){
        this.toast.info(timestamp,displayName)
    }
    ngOnInit(): void
    {
      
     
            $(document).ready(function() {
                $('.escoderComentarios').hide();
                $('.verComentarios_u').hide();
              }); 
    }
    verComentarios(k:string){
        $('.escoderComentarios'+k).toggle();
        $('.verComentarios_d_'+k).toggle();
        $('.verComentarios_u_'+k).toggle();
    }
    botonEnterado(kA:string,kB?:string,kC?:string){
        console.log(kA,kB,kC);
        this._JMYTimeLineService.botonEnterado({
            kA:kA,
            kB:kB,
            kC:kC
        }).then(r=>{

            console.log(r);
            
        })
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

export class configJmy{
    usuario?:{
        photoProfile?:string
    }
    cliente:{
        projectId:string
        logo?:string
        fotoPortada?:string[]
        cargaArchivos:string
        tema?:{
            login      ?:string
            colorTheme      ?:string
            customScrollbars?: boolean
            layout          ?: {
                style    ?: string
                width    ?: string
                navbar   ?: {
                    primaryBackground  : string
                    secondaryBackground: string
                    folded             : boolean
                    hidden             : boolean
                    position           : string
                    variant            : string
                },
                toolbar  ?: {
                    customBackgroundColor: boolean
                    background           : string
                    hidden               : boolean
                    position             : string
                },
                footer   ?: {
                    customBackgroundColor: boolean
                    background           : string
                    hidden               : boolean
                    position             : string
                },
                sidepanel?: {
                    hidden  : boolean
                    position: string
                }
            }
        }
    }
    app:{
        nombre:string
        eid:string
        key:string
        servidor:string
        urls?:{
            sitioWeb:string
            terminosYCondiciones:string
            avisoDePrivacidad:string
        }, 
        ecard?:[{
            nombre:string
            url:string
            img:string
        }],
        routes?:{
            login:string
            inicio:string
        }
    }
    jmyConfig:any;
    constructor(dI:any){
        let d:any=dI.jmyConfig;
        d.usuario=dI.usuario||{}
        this.usuario={
            photoProfile:d.usuario.photoProfile||'./assets/images/logos/ico_700x700.png',
            ...d.usuario
        };

        if( d.cliente==undefined)
            d.cliente={}
        if( d.cliente['tema']==undefined)
            d.cliente['tema']={}
        if( d.cliente['tema']['login']==undefined)
            d.cliente['tema']['login']=''
        if( d.cliente['tema']['colorTheme']==undefined)
            d.cliente['tema']['colorTheme']='theme-blue-gray-dark'
        if( d.cliente['tema']['layout']==undefined)
            d.cliente['tema']['layout']={}
        
        this.cliente={
            projectId:d.cliente['projectId']||'demo',
            logo:"assets/images/logos/fuse.svg",
            fotoPortada:[
                '/assets/images/backgrounds/boscotron/1.jpg',
                '/assets/images/backgrounds/boscotron/2.jpg',
                '/assets/images/backgrounds/boscotron/3.jpg',
                '/assets/images/backgrounds/boscotron/4.jpg',
                '/assets/images/backgrounds/boscotron/5.jpg'
            ],
            cargaArchivos:d.cliente.cargaArchivos||"https://comsis.mx/cargando-archivos/",
            tema:{
                login      :d.cliente.tema.login,
                colorTheme      :d.cliente.tema.colorTheme,
                customScrollbars: true,
                layout          :{
                    style    : 'vertical-layout-1',
                    width    : 'fullwidth',
                    navbar   : {
                        primaryBackground  :'fuse-navy-700',
                        secondaryBackground:'fuse-navy-900',
                        folded             :false,
                        hidden             :true,
                        position           :'left',
                        variant            :'vertical-style-1',
                    },
                    toolbar  : {
                        customBackgroundColor: false,
                        background           : 'fuse-white-500',
                        hidden               : false,
                        position             : 'below-static',
                    },
                    footer   : {
                        customBackgroundColor:true,
                        background           :'fuse-navy-900',
                        hidden               :true,
                        position             :'below-fixed',
                    },
                    sidepanel: {                        
                        hidden  : false,
                        position: 'right',
                       
                    },
                },
        
                ...d.cliente.layout
            },
        }
       
        if(d.app==undefined)
            d.app={}
        this.app={
            nombre:d.app.nombre||'Jmy 4 Beta',
            eid:d.app.eid||'',
            key:d.app.key||'',
            servidor:d.app.servidor||'http://comsisauhtserve1.ddns.net:5001/concomsis-auth-server/us-central1/beta/',
            urls:{
                sitioWeb:'',
                terminosYCondiciones:'',
                avisoDePrivacidad:'',
                ...d.app.urls||{}
            }, 
            ecard:d.app.ecard||[{
                nombre:'Concomsis eCard',
                url:'',
                img:''
            }],
            routes:{
                login:'a/auth/login',
                inicio:'apps/dashboards/jmydashboard',
                ...d.app.routes||{}
            },
            ...d.app
        }
        this.jmyConfig = {app:this.app, usuario:this.usuario, cliente:this.cliente}
    }
    
}
export class configEnv{
    production:boolean
    hmr:boolean
    firebase:{
        apiKey:string
        authDomain:string
        databaseURL:string
        projectId:string
        storageBucket:string
        messagingSenderId:string  
    }
    constructor(d:any){
        //console.log(d);
        
        this.production=d.production||false;
        this.hmr=d.hmr||false;
        this.firebase={
            apiKey: "AIzaSyCodyfVPqagq5h-sBMSUHVssfTaPvM35Iw",
            authDomain: "encouraging-mix-111109.firebaseapp.com",
            databaseURL: "https://encouraging-mix-111109-admin.firebaseio.com/",
            projectId: "encouraging-mix-111109",
            storageBucket: "encouraging-mix-111109.appspot.com",
            messagingSenderId: "200860264133",
            ...d
        }
        //console.log(d,this.firebase);
    }
}


const configuraciones :any =  {    
    "PanelDemoAuto":{
        usuario:{
            photoProfile:  './assets/images/backgrounds/logos/ico_700x700.png' 
        },
        jmyConfig:{
            cliente:{
                projectId:"PanelDemoAuto"              
            }, 
            app:{
                nombre:'Panel Demo Automotriz',
                eid:"PanelDemoAuto", //Comsis
                key:"2ccf86e440e9a50cfa6274776fe03dd4",//Comsis
                //servidor:"https://us-central1-concomsis.cloudfunctions.net/beta/",  
                ecard:[{
                    nombre:'Concomsis eCard',
                    url:'https://comsis.mx/e/0/',
                    img:'https://comsis.mx/att/static/jmy4/angular/fuse/assets/logoIco.png'
                }],
            }
        },
        firebase: {
            databaseURL: "https://encouraging-mix-111109-demo-automotriz.firebaseio.com/",
        }
    },
    "OlvidateDelEstres":{
        usuario:{
            photoProfile:  './assets/images/backgrounds/logos/ico_700x700.png' 
        },
        jmyConfig:{
            cliente:{
                projectId:"2669ecf9878ac6fbda4972e74f976a39"              
            }, 
            app:{
                nombre:'Olvidate del estres',
                eid:"9697e5f78a7191dd8c965ae4f920944c", //Comsis
                key:"2669ecf9878ac6fbda4972e74f976a39",//Comsis
                //servidor:"https://us-central1-concomsis.cloudfunctions.net/beta/",  
                ecard:[{
                    nombre:'Concomsis eCard',
                    url:'https://comsis.mx/e/0/',
                    img:'https://comsis.mx/att/static/jmy4/angular/fuse/assets/logoIco.png'
                }],
            }
        },
        firebase: {
            databaseURL: "https://encouraging-mix-111109-admin.firebaseio.com/",
        }
    },
    "concomsis-san-miguel":{
        usuario:{
            photoProfile:  './assets/images/logos/ico_700x700-san.png' 
        },
        jmyConfig:{
            cliente:{
                projectId:"concomsis-panel",
                logo:"assets/images/logos/fuse-san.svg",
                tema:{
                    login      : 'tradicional', 
                    colorTheme : 'theme-san-mi-light', 
                }
            }, 
            app:{
                nombre:'San Miguel', //Comsis
                eid:"concomsis-panel", //Comsis
                key:"2ccf86e440e9a50cfa6274776fe03dd4",//Comsis
                servidor:"https://us-central1-concomsis.cloudfunctions.net/beta/",  
                ecard:[{
                    nombre:'Concomsis eCard',
                    url:'https://comsis.mx/e/0/',
                    img:'https://comsis.mx/att/static/jmy4/angular/fuse/assets/logoIco.png'
                }],
            }
        },
        firebase: {
            databaseURL: "https://encouraging-mix-111109-comsis-mx.firebaseio.com/",
        }
    },
    "concomsis-panel-konhaus":{
        usuario:{
            photoProfile:  './assets/images/logos/ico_700x700-konhaus.png' 
        },
        jmyConfig:{
            cliente:{
                projectId:"concomsis-panel",
                logo:"assets/images/logos/fuse-komhaus.svg",
                tema:{
                    login      : 'tradicional', 
                    colorTheme      : 'theme-haus-light', 
                }
            }, 
            app:{
                nombre:'KonHaus', //Comsis
                eid:"concomsis-panel", //Comsis
                key:"2ccf86e440e9a50cfa6274776fe03dd4",//Comsis
                servidor:"https://us-central1-concomsis.cloudfunctions.net/beta/",  
                ecard:[{
                    nombre:'Concomsis eCard',
                    url:'https://comsis.mx/e/0/',
                    img:'https://comsis.mx/att/static/jmy4/angular/fuse/assets/logoIco.png'
                }],
            }
        },
        firebase: {
            databaseURL: "https://encouraging-mix-111109-comsis-mx.firebaseio.com/",
        }
    },
    "storm-cacher-sand":{

        jmyConfig:{
            usuario:{
                photoProfile:  './assets/images/backgrounds/logos/ico_700x700.png' 
            },
            cliente:{
                projectId:"a07bd76074c0a795174e674ec04a18cf",
                logo:"assets/images/logos/fuse-stormcatcher.svg",
                tema:{
                    login      : 'tradicional', 
                    colorTheme      : 'theme-stormcatcher-dark', 
                }
            }, 
            app:{
                nombre:'Panel Storm Catcher (SAND)',
                eid:"a07bd76074c0a795174e674ec04a18cf", 
                key:"1dd8c965ae4f920944c9697e5f78a719",
               // servidor:"https://us-central1-concomsis.cloudfunctions.net/beta/",            
               servidor:"http://localhost:5001/concomsis/us-central1/beta/",   
                ecard:[{
                    nombre:'Concomsis eCard',
                    url:'https://comsis.mx/e/0/',
                    img:'https://comsis.mx/att/static/jmy4/angular/fuse/assets/logoIco.png'
                }],
            }
        },
        firebase: {
            databaseURL: "https://encouraging-mix-111109-storm-catcher-sand-concomsis.firebaseio.com/",
        }
    },
    "storm-cacher":{

        jmyConfig:{
            usuario:{
                photoProfile:  './assets/images/logos/fuse-stormcatcher.svg' 
            },
            cliente:{
                projectId:"a07bd76074c0a795174e674ec04a18cf",
                logo:"assets/images/logos/fuse-stormcatcher.svg",
                tema:{
                    login      : 'tradicional', 
                    colorTheme      : 'theme-luice-light', 
                }
            }, 
            app:{
                nombre:'Panel Storm Catcher',
                eid:"a07bd76074c0a795174e674ec04a18cf", 
                key:"1dd8c965ae4f920944c9697e5f78a719",
                servidor:"https://us-central1-concomsis.cloudfunctions.net/beta/",            
                ecard:[{
                    nombre:'Concomsis eCard',
                    url:'https://comsis.mx/e/0/',
                    img:'https://comsis.mx/att/static/jmy4/angular/fuse/assets/logoIco.png'
                }],
            }
        },
        firebase: {
            databaseURL: "https://encouraging-mix-111109-storm-catcher-concomsis.firebaseio.com/",
        }
    },
    "tpk":{
        jmyConfig:{
            usuario:{
                photoProfile:  './assets/images/logos/ico_700x700.png' 
            },
            cliente:{
                projectId:"a07bd76074c0a795174e674ec04a18cf",//concomsis-panel
            }, 
            app:{
                nombre:'Intranet Storm Catcher', //Comsis
                eid:"a07bd76074c0a795174e674ec04a18cf", //Comsis
                key:"2ccf86e440e9a50cfa6274776fe03dd4",//Comsis
                servidor:"https://us-central1-concomsis.cloudfunctions.net/beta/",            
               // servidor:"http://localhost:5000/concomsis/us-central1/beta/",   
                ecard:[{
                    nombre:'Concomsis eCard',
                    url:'https://comsis.mx/e/0/',
                    img:'https://comsis.mx/att/static/jmy4/angular/fuse/assets/logoIco.png'
                }],
            }
        },
        firebase: {
            databaseURL: "https://encouraging-mix-111109-storm-catcher-concomsis.firebaseio.com/",
        }
    },
    "netCloudServices":{

        jmyConfig:{
            usuario:{
                photoProfile:  './assets/images/logos/ico_700x700.png' 
            },
            cliente:{
                projectId:"a07bd76074c0a795174e674ec04a18cf",//concomsis-panel
            }, 
            app:{
                nombre:'Intranet Storm Catcher', //Comsis
                eid:"a07bd76074c0a795174e674ec04a18cf", //Comsis
                key:"2ccf86e440e9a50cfa6274776fe03dd4",//Comsis
                servidor:"https://us-central1-concomsis.cloudfunctions.net/beta/",            
               // servidor:"http://localhost:5000/concomsis/us-central1/beta/",   
                ecard:[{
                    nombre:'Concomsis eCard',
                    url:'https://comsis.mx/e/0/',
                    img:'https://comsis.mx/att/static/jmy4/angular/fuse/assets/logoIco.png'
                }],
            }
        },
        firebase: {
            databaseURL: "https://encouraging-mix-111109-storm-catcher-concomsis.firebaseio.com/",
        }
    },
    "coworkingNest":{

        jmyConfig:{
            usuario:{
                photoProfile:  './assets/images/logos/ico_700x700.png' 
            },
            cliente:{
                projectId:"a07bd76074c0a795174e674ec04a18cf",//concomsis-panel
            }, 
            app:{
                nombre:'Intranet Storm Catcher', //Comsis
                eid:"a07bd76074c0a795174e674ec04a18cf", //Comsis
                key:"2ccf86e440e9a50cfa6274776fe03dd4",//Comsis
                servidor:"https://us-central1-concomsis.cloudfunctions.net/beta/",            
               // servidor:"http://localhost:5000/concomsis/us-central1/beta/",   
                ecard:[{
                    nombre:'Concomsis eCard',
                    url:'https://comsis.mx/e/0/',
                    img:'https://comsis.mx/att/static/jmy4/angular/fuse/assets/logoIco.png'
                }],
            }
        },
        firebase: {
            databaseURL: "https://encouraging-mix-111109-storm-catcher-concomsis.firebaseio.com/",
        }
    },
    "dieta":{

        jmyConfig:{
            usuario:{
                photoProfile:  './assets/images/logos/ico_700x700.png' 
            },
            cliente:{
                projectId:"a07bd76074c0a795174e674ec04a18cf",//concomsis-panel
            }, 
            app:{
                nombre:'Intranet Storm Catcher', //Comsis
                eid:"a07bd76074c0a795174e674ec04a18cf", //Comsis
                key:"2ccf86e440e9a50cfa6274776fe03dd4",//Comsis
                servidor:"https://us-central1-concomsis.cloudfunctions.net/beta/",            
               // servidor:"http://localhost:5000/concomsis/us-central1/beta/",   
                ecard:[{
                    nombre:'Concomsis eCard',
                    url:'https://comsis.mx/e/0/',
                    img:'https://comsis.mx/att/static/jmy4/angular/fuse/assets/logoIco.png'
                }],
            }
        },
        firebase: {
            databaseURL: "https://encouraging-mix-111109-storm-catcher-concomsis.firebaseio.com/",
        }
    },
    "concomsis-panel":{
        jmyConfig:{
            cliente:{
                projectId:"concomsis-panel",//concomsis-panel
            }, 
            app:{
                nombre:'Jmy 4 Beta', //Comsis
                eid:"concomsis-panel", //Comsis
                key:"2ccf86e440e9a50cfa6274776fe03dd4",//Comsis
               // servidor:"https://us-central1-concomsis.cloudfunctions.net/beta/",            
               // servidor:"http://localhost:5001/concomsis/us-central1/beta/",   
                ecard:[{
                    nombre:'Concomsis eCard',
                    url:'https://comsis.mx/e/0/',
                    img:'https://comsis.mx/att/static/jmy4/angular/fuse/assets/logoIco.png'
                }],
            }
        },
        firebase: {
            databaseURL: "https://encouraging-mix-111109-comsis-mx.firebaseio.com/",
        }
    },
    "concomsis-panel-sand":{
        jmyConfig:{
            cliente:{
                projectId:"concomsis-panel",//concomsis-panel
            }, 
            app:{
                nombre:'Jmy 4 Beta', //Comsis
                eid:"concomsis-panel", //Comsis
                key:"2ccf86e440e9a50cfa6274776fe03dd4",//Comsis
               // servidor:"https://us-central1-concomsis.cloudfunctions.net/beta/",            
                servidor:"http://localhost:5001/concomsis/us-central1/beta/",   
                ecard:[{
                    nombre:'Concomsis eCard',
                    url:'https://comsis.mx/e/0/',
                    img:'https://comsis.mx/att/static/jmy4/angular/fuse/assets/logoIco.png'
                }],
            }
        },
        firebase: {
            databaseURL: "https://encouraging-mix-111109-comsis-mx.firebaseio.com/",
        }
    },
    "alrunet":{
        jmyConfig:{
            cliente:{
                projectId:"concomsis-panel",//concomsis-panel
            }, 
            app:{
                nombre:'Panel Alrunet', //Comsis
                eid:"concomsis-panel", //Comsis
                key:"2ccf86e440e9a50cfa6274776fe03dd4",//Comsis
                servidor:"https://us-central1-concomsis.cloudfunctions.net/beta/",            
               // servidor:"http://localhost:5001/concomsis/us-central1/beta/",   
                ecard:[{
                    nombre:'Concomsis eCard',
                    url:'https://comsis.mx/e/0/',
                    img:'https://comsis.mx/att/static/jmy4/angular/fuse/assets/logoIco.png'
                }],
            }
        },
        firebase: {
            databaseURL: "https://encouraging-mix-111109-alrunet.firebaseio.com/",
        }
    },
    "luice":{
        jmyConfig:{
            cliente:{
                projectId:"concomsis-panel",//concomsis-panel
                logo:"assets/images/logos/fuse-luice.svg",
                fotoPortada:[
                    '/assets/images/backgrounds/luice/1.jpg'
                ],
                tema:{
                    //login      : 'tradicional', 
                    colorTheme      : 'theme-luice-light', 
                }
            }, 
            
            app:{
                nombre:'Panel Luice', //Comsis
                eid:"concomsis-panel", //Comsis
                key:"2ccf86e440e9a50cfa6274776fe03dd4",//Comsis
                servidor:"https://us-central1-concomsis.cloudfunctions.net/beta/",            
              //  servidor:"http://localhost:5001/concomsis/us-central1/beta/",   
                ecard:[{
                    nombre:'Concomsis eCard',
                    url:'https://comsis.mx/e/0/',
                    img:'https://comsis.mx/att/static/jmy4/angular/fuse/assets/logoIco.png'
                }],
            }
        },
        firebase: {
            databaseURL: "https://encouraging-mix-111109-luice.firebaseio.com/",
        }
    }
}


 


//const licencia="concomsis-panel";
//const licencia="alrunet";
//const licencia="concomsis-san-miguel";
//const licencia="concomsis-panel-konhaus";
//const licencia="storm-cacher";
const licencia="luice";
export const environment = new configEnv(configuraciones[licencia].firebase); 
export const jmyConfig = new configJmy(configuraciones[licencia]);

import { JmyService  } from '../jmy.service';
import { ArchivosConfigInterface  } from '../interfaces/services';
import * as _M from 'md5';
import { Subject, BehaviorSubject } from 'rxjs';
import { take,takeUntil } from 'rxjs/operators';
import { url } from 'inspector';
import { ToastrService } from 'ngx-toastr';
import { Component, ViewEncapsulation, ViewChild, ElementRef, PipeTransform, Pipe, OnInit,OnDestroy} from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";


@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-jmy-archivos',
  templateUrl: './jmy-archivos.component.html',
  styleUrls: ['./jmy-archivos.component.scss']
})
export class JmyArchivosComponent implements OnInit,OnDestroy {
  vista:boolean=false;
  empresa:BehaviorSubject<object>
  urlTarget:string;
  lista:any;
  private urlTargetDef:string='';
  // Private
  private _unsubscribeAll: Subject<any>;
  treeArchivos:any;
  constructor(
    private jmyService:JmyService,
    private toastr:ToastrService,
  ) { 
    this.vista=false;
    this.urlTargetDef=this.jmyService.config.cliente.cargaArchivos;
    this.urlTarget=this.urlTargetDef;
    this.lista=[];
    this.empresa=new BehaviorSubject(null);
    this.jmyService.jmyPerfil.datosEmpresa().then(r=>{
      this.empresa.next(r);
      console.log(r);      
    })

    this.treeArchivos=this.jmyService.jmyFn.treeArchivos;
    this.treeArchivos.subscribe(r=>{
     // console.log(r);
      if(this.urlTarget!=r.url_iframe['url'])
        this.urlTarget=r.url_iframe['url']||this.urlTargetDef;
    })
    this.jmyService.jmyFn.configuracionArchivos.subscribe(cA=>{
      this.urlTarget=this.urlTargetDef;
      this.jmyService.jmyFn.botonEnEsperaArchivos.next(true);
      this.jmyService.jmyFn.archivos(cA).then(archivos=>{
        this.jmyService.jmyFn.botonEnEsperaArchivos.next(false);
        console.log(archivos);
        if(archivos['url_iframe']!=undefined)
          this.urlTarget=archivos['url_iframe']['url']||this.urlTargetDef;

      }).catch(err=>{
        console.error(err);
        this.toastr.error(err['mensaje'],'Error  :( ');
      })

    })
  }
   reset(){
    this.urlTarget = this.urlTargetDef;
    return this.urlTarget 
   }

  ngOnInit() {
    this.vista=false;
    /*let otraConfig:ArchivosConfigInterface = {
      ruta:'',
      modulo:'',
      raiz:'',
      permiso:'',
    }*/
    
  }    /**
  * On destroy
  */
 ngOnDestroy(): void
 {
     // Unsubscribe from all subscriptions
    // this.treeArchivos.unsubscribe();
     //this._unsubscribeAll.next();
    // this._unsubscribeAll.complete();
 }

}

export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'APPLICATIONSALPHA': 'Applications ALPHA',
            'DASHBOARDS'  : 'Dashboards',
            'CALENDAR'    : 'Calendar',
            'ECOMMERCE'   : 'E-Commerce',
            'ACADEMY'     : 'Academy',
            'MAIL'        : {
                'TITLE': 'Mail',
                'BADGE': '25'
            },
            'MAIL_NGRX'        : {
                'TITLE': 'Mail Ngrx',
                'BADGE': '13'
            },
            'CHAT'        : 'Chat',
            'FILE_MANAGER': 'File Manager',
            'CONTACTS'    : 'Contacts',
            'TODO'        : 'To-Do',
            'SCRUMBOARD'  : 'Scrumboard',
            'ALMACEN'  : 'Store',
            'ALMACENPRODUCTOS'  : 'Products',
            'SEMINUEVOS'  : 'Preowned cars',
            'SEMINUEVOSUNIDADES'  : 'Cars',
        }
    }
};

import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { Router } from '@angular/router';
import { jmyConfig } from 'environments/environment';
import { auth } from 'firebase';
import { map } from 'rxjs/operators';
import { navInterface, loginEmailInterface, RedireccionarInterface, emailInterface, authInterface, infoPerfilInterface } from '../interfaces/services';
import { JmyPerfil } from "./JmyPerfil";
import { promise } from 'protractor';
import {  BehaviorSubject} from 'rxjs';


@Injectable()
export class JmyLogin {
  config: navInterface = jmyConfig;
  interface:BehaviorSubject<object>;
  constructor(
    public location: Location,
    public afAuth: AngularFireAuth,
    private router: Router,
    public jmyPerfil: JmyPerfil,
    public db: AngularFireDatabase
  ) {
    this.interface=new BehaviorSubject( {
      layout: {
          navbar   : {
              hidden: true
          },
          toolbar  : {
              hidden: true
          },
          footer   : {
              hidden: true
          },
          sidepanel: {
              hidden: true
          }
      }
  });
    this.afAuth.user.subscribe(r=>{
      
//      console.log(r);
      if(!r)
      this.interface.next( {
        layout: {
            navbar   : {
                hidden: true
            },
            toolbar  : {
                hidden: true
            },
            footer   : {
                hidden: true
            },
            sidepanel: {
                hidden: true
            }
        }
    });
    else
      this.jmyPerfil.cargaPerfil({
        nombre:r.displayName,
        email:r.email,
        uid:r.uid,
        phoneNumber:r.phoneNumber,
        photoURL:r.photoURL,
        displayName:r.displayName,
        emailVerified:r.emailVerified,
        eid:this.config.cliente.projectId
      }).then(p=>{
        if(p.uid != ''){
          this.interface.next( {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        });
      }else{
        this.interface.next( {
          layout: {
              navbar   : {
                  hidden: false
              },
              toolbar  : {
                  hidden: false
              },
              footer   : {
                  hidden: true
              },
              sidepanel: {
                  hidden: false
              }
          }
      });
      }
        
      }).catch(r=>{
        console.log(r)
        });
    })

   }
  sesion(d: RedireccionarInterface = {
    redireccionar: jmyConfig.app.routes.login
  }) {
    return new Promise((res, rej) => {
      if (this.jmyPerfil.perfil != undefined)
        res(this.jmyPerfil.perfil);
      else
        this.router.navigate([d.redireccionar]);
      rej(this.jmyPerfil.perfil);
    })
  }
  google(d: RedireccionarInterface = {
    redireccionar: '/'
  }) {
    return new Promise((o, er) => {
      this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider()).then(res => {
       
        this.cargarPerfil( res.user ).then(r=>{
          location.assign(location.origin+'/'+d.redireccionar)
        });
      }).catch(err => { er(err) })
    })
  }
  facebook(d: RedireccionarInterface = {
    redireccionar: '/'
  }) {
    return new Promise((o, er) => {
      this.afAuth.auth.signInWithPopup(new auth.FacebookAuthProvider()).then(res => {
        
        this.cargarPerfil( res.user ).then(r=>{
          //console.warn('----',location.origin+'/'+d.redireccionar);
          location.assign(location.origin+'/'+d.redireccionar)
        });
      }).catch(err => { er(err) })
    })
  }
  email(d: loginEmailInterface = {
    "email": null, "pass": null, "redireccionar": '/'
  }) {
    return new Promise((o, er) => { 
      this.afAuth.auth.signInWithEmailAndPassword(d.email, d.pass).then(res => {
        
        this.cargarPerfil( res.user ).then(r=>{
          location.assign(location.origin+'/'+d.redireccionar)
        });
      }, err => { er(err) })
    })
  }
  logOut(d: RedireccionarInterface = {
    "redireccionar": null
  }) {
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem('jmy_AcP')
      if (d.redireccionar != undefined)
        location.assign(location.origin+'/'+d.redireccionar)
    });
  }
  photoProfile(d: loginEmailInterface = {
    email: null, pass: null, displayName: null
  }) {
    return new Promise((res, rej) => {

    })
  }
  singinEmail(d: loginEmailInterface = {
    email: null, pass: null, displayName: null, redireccionar: null
  }) {
    let _ = this
    return new Promise((res, rej) => {
      this.afAuth.auth.createUserWithEmailAndPassword(d.email, d.pass)
        .catch(e => {
          if (e.message != undefined)
            rej(e.message)
        }).then(r => {
          this.actualizarAuth({
            displayName:d.displayName,
            photoURL: _.config.usuario.photoProfile
          });
          this.cargarPerfil(r);
            if (d.redireccionar != undefined)
              location.assign(location.origin+'/'+d.redireccionar)
            res(true)
        });
    })
  }
  actualizarAuth(d:authInterface){
      return new Promise ((res,rej)=>{
        this.getCurrentUser().subscribe(u => {        
          if (u != undefined){
              
            this.jmyPerfil.actualizarPerfil({
              photoURL:d.photoURL,
              displayName:d.displayName,
              uTipo:d.uTipo,
              email:null,
              nombre:null,
              uid:null,
              phoneNumber:null,
              emailVerified:null,
              eid:null
            })
            u.updateProfile(d).then(ue => {
              console.log('updateProfile',ue);
              //res(true)
            }).catch(e => { });
              
              let user = this.afAuth.auth.currentUser;
              user.updateProfile({
                displayName: u.displayName,
                photoURL: u.photoURL
              }).then(function() {
                  res(true);
              }).catch(function(error) {
                  rej(error);
                console.error(error);
              });
          }
        })  
      })
  }
  recoveryPass(d: emailInterface = {
    "email": null
  }) {
    return new Promise((res, rej) => {
      this.afAuth.auth.sendPasswordResetEmail(d.email).catch(r => {
        console.log('c', r);
        rej(r)
      }).then(rb => {
        res(true);
      })
    })
  }
  getCurrentUser() {
    return this.afAuth.authState.pipe(map(auth => auth));
  }
  doLogout() {
    return new Promise((o, er) => {
      this.logOut().catch(r => {
        er(true);
      }).then(r => {
        o();
      })
    });
  }
  private cargarPerfil(d:any) {    
    return new Promise((o,er)=>{
      console.log('cargarPerfil',d);      
      this.jmyPerfil.cargaPerfil({
        nombre:d.displayName,
        email:d.email,
        uid:d.uid,
        phoneNumber:d.phoneNumber,
        photoURL:d.photoURL,
        displayName:d.displayName,
        emailVerified:d.emailVerified,
        eid:this.config.cliente.projectId
      }).then(r=>{o(r)}).catch(r=>{er(r)});
    })
    
  }
}

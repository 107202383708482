import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JmyService} from 'app/concomsis/jmy/jmy.service';
import { infoPerfilInterface,
    perfilAcercaInterface, 
    TimelinePostInterface,
    TimelinePostConfigInterface,
    TimelinePostCommentInterface,
    TimelinePostActionsUserInterface,
    TimelinePostStatesUserInterface,
    TimelinePostIndexSalasInterface,
    UserInterface,
    
 } from 'app/concomsis/jmy/interfaces/services';
import { log } from 'util';


@Injectable()
export class JMYTimeLineService implements Resolve<any>
{
    timeline: any;
    perfil: any;
    about: perfilAcercaInterface;
    profile:infoPerfilInterface;
    photosVideos: any;
    customers: any;
    routeParams: any;
    timelineOnChanged: BehaviorSubject<any[]>;
    config: BehaviorSubject<TimelinePostConfigInterface>;
    configOn: TimelinePostConfigInterface;
    aboutOnChanged: BehaviorSubject<any>;
    photosVideosOnChanged: BehaviorSubject<any>;
    uid:BehaviorSubject<string>;
    private fnQTime:any;
    likeUser:object;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    
    constructor(
        private _httpClient: HttpClient,
        private jmyService:JmyService
    )
    {
        // Set the defaults
        this.timelineOnChanged = new BehaviorSubject(null);
        this.aboutOnChanged = new BehaviorSubject({});
        this.photosVideosOnChanged = new BehaviorSubject({});
        this.uid = new BehaviorSubject(this.jmyService.jmyPerfil.perfil.uid);
        this.config = new BehaviorSubject(new TimelinePostConfigInterface({ruta:'timeline/salas/privado/'+this.jmyService.jmyPerfil.perfil.uid+'/'}));
        this.likeUser={}
        this.config.subscribe(d=>{
            this.configOn = d;
            const rT:string[]=this.configOn.ruta.split("/").filter(Boolean)||[];
            let lT:boolean=(rT[rT.length-1]=='_timeline')?true:false;//Listado
            console.log(this.configOn);
            if(rT[rT.length-1]&&this.configOn.despliegue.length>0){
                let rt = this.configOn.ruta+((!lT)?'/_timeline/':''),
                    ch = 'config/rl',
                    to = this.despliege(this.configOn),
                    lm = 200,
                    red= String(this.configOn.ruta+((!lT)?'_timeline/':'')).replace('//','/');

                console.log(red,ch,to);
                this.fnQTime = this.jmyService.dbRealTime.list(red, 
                    ref => ref.orderByChild(ch).startAt(to.startAt).endAt(to.endAt).limitToLast(lm)).snapshotChanges()
                    .subscribe(r=>{                  
                    //let uR:string[]=r.map(c => (c.payload.key));
                    let qR:any[]=(lT)?r.map(c => (c.payload.val())):r.map(c => ({  ...c.payload.val(), key: c.payload.key }));
                    if(lT){
                        let qRt:any[]=qR;
                        qR=[];
                        Object.keys(qRt).forEach(r=>{
                        Object.keys(qRt[r]).forEach(rb=>{
                            qR.push(qRt[r][rb])
                        })
                        })
                    }
                    
                    console.log(qR,this.config);
                    
                    this.timeline = qR.map(tT => {
                        let b:boolean=false;
                        let likeUDisplay:TimelinePostActionsUserInterface[]=[];
                        if(tT.likeU!=undefined)
                            Object.keys(tT.likeU).forEach(eL => {
                                let tm = tT.likeU[eL];                                    
                                if(tm.state=="1"){
                                    let gT = {
                                        uid:tm.uid,
                                        photoURL:tm.photoURL,
                                        displayName:tm.displayName,
                                        timestamp:tm.timestamp,
                                    };
                                    likeUDisplay.push(gT) 
                                }
                                    
                                if(this.jmyService.jmyPerfil.perfil.uid==tm.uid){
                                    // console.log(tm,tm.uid,this.jmyService.jmyPerfil.perfil.uid);
                                    this.likeUser[tT.key]=(tm.state==="1")?true:false;
                                    b=(tm.state==="1")?true:false;
                                }else{
                                    b=(!b)?false:b;
                                }
                            });
                        tT.likeUDisplay=likeUDisplay;
                        tT.banderaLike=(b)?'primary':'';
                        //console.log(tT);
                        return tT
                    })||[];
                    //console.log(this.timeline,this.likeUser);
                    this.timelineOnChanged.next(this.timeline);
                    //resolve(this.timeline);
                    console.log(this.timeline);
                    
                    
                })
            }else{
                this.fnQTime = null;
                console.log('null',this.configOn);
            }
        });

        
        
    }
    private despliege(config:TimelinePostConfigInterface={}):{
        startAt:string,
        endAt:string
    }{
        let o={
            startAt:'',
            endAt:''
        };
        config=new TimelinePostConfigInterface(config)
        config.despliegue.forEach(r=>{
            switch (r) {
                case 'capa-0':
                    o.startAt=config.ruta+config.sala.split('__')[0];
                    o.endAt=config.ruta+config.sala.split('__')[0]+'\uf8ff';
                break;
                case 'sala':
                    o.startAt=config.ruta+config.sala
                    o.endAt=config.ruta+config.sala
                break;
                default:
                    o.startAt=config.rl||config.ruta+config.sala
                    o.endAt=config.rl||config.ruta+config.sala
            }
        })
        o.startAt=o.startAt.replace('//','/');
        o.endAt=o.endAt.replace('//','/');
        console.log(config,o);
        return o
    }
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;
        this.uid.next(this.routeParams.uid||this.jmyService.jmyPerfil.uid);
        console.log('-------',route, this.routeParams ,this.uid);
        return new Promise((resolve, reject) => {
            Promise.all([
                
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get Info
     */
    getInfo(uid:string): Promise<any[]>
    {
        return new Promise((resolve, reject) => {
            const q=this.jmyService.dbRealTime.object(this.jmyService.jmyPerfil.rutaPublica+'lista/'+uid).valueChanges().subscribe(r=>{
                this.perfil = r;
                resolve(this.perfil)
            });
          
        });
    }
    /**
     * Get timeline
     */
    miLike(key){
        return false
    }
    getTimeline(uid?:string,dOld?:TimelinePostConfigInterface): Promise<any[]>
    {
     /*   return new Promise((res,rej)=>{
            this.jmyService.jmyFn.getTimeline(uid,d).then(r=>{
                console.log('respuesta_remota',r);
                res(r);
            }).catch(r=>rej(r))
        });*/
        
        return new Promise((resolve, reject) => {
           
        });

    }
    postTimeline(d:any){
        return new Promise((res,rej)=>{        
            const rT:string[]=this.configOn.ruta.split("/").filter(Boolean)||[];
            let lT:boolean=(rT[rT.length-1]=='_timeline')?true:false;//Listado
            
            d=new TimelinePostInterface({...d,
                ruta:this.configOn.ruta,
                sala:this.configOn.sala,
                comentarioDest:d.comentarioDest||null,
                timestamp:d.timestamp||this.jmyService.jmyFn.fnT.tiempo(), 
                view:this.jmyService.jmyPerfil.perfil.uid,
                user:{
                    uid:this.jmyService.jmyPerfil.perfil.uid,
                    name:this.jmyService.jmyPerfil.perfil.displayName,
                    avatar:this.jmyService.jmyPerfil.perfil.photoURL,
                }
            })
            d.config=new TimelinePostConfigInterface(this.configOn);
            console.log(d,this.configOn,lT);
            this.jmyService.dbRealTime.list(d.ruta+((!lT)?'/_timeline/':'')).push(d).then(r=>{
                this.jmyService.dbRealTime.list('/concomsis/sys/_timeline/').push(
                    new TimelinePostIndexSalasInterface({
                        ...d,
                        id:d.id,
                        usuarios:[new UserInterface(d.user)],
                        usuariosLeido:[new UserInterface(d.user)],
                        usuariosNotifiaciones:'',
                        rutaDB:'',
                })).then(rb=>{
                    console.log(this.configOn,d.ruta);
                    res(d);
                }).catch(rb=>{
                    rej({d,rb});
                }); 
            }).catch(r=>{
                rej({d,r});
            });
        })
    }
    botonEnterado(k:{
        kA:string
        kB?:string
        kC?:string
        kD?:string
    },d?:TimelinePostConfigInterface){
        return new Promise((res,rej)=>{
            const jmP=this.jmyService.jmyPerfil.perfil;
            const r:string=this.configOn.ruta+'/'+k.kA+
            ((k.kB!=undefined)?'/comments/'+k.kB:'')+
            ((k.kC!=undefined)?'/comments/'+k.kC:'')+
            ((k.kD!=undefined)?'/comments/'+k.kD:'');
            let tP:TimelinePostInterface=this.timeline.find( qF=>qF.key==k.kA );            
            if(tP.likeU==undefined)
                tP['likeU']=[];
            let g:TimelinePostActionsUserInterface={
                uid         : jmP.uid,
                photoURL    : jmP.photoURL,
                displayName : jmP.displayName,
                timestamp   : this.jmyService.jmyFn.fnT.tiempo(),
                state       : tP.likeU['state']||0,
            };
            if(Object.keys(tP.likeU).includes(jmP.uid)){ 
                g.state=new TimelinePostStatesUserInterface({state:(tP.likeU[jmP.uid]['state']==='on')?'on':'off'});
            }
            tP.likeU[jmP.uid]=g;
            Object.keys(tP.likeU).forEach(tPlU => {
                if(tP.likeU[tPlU]['state']=='on')
                    tP.like=tP.like+1
                
            });
            console.log(r,tP);
            const q=this.jmyService.dbRealTime.object(r);
            q.set(tP);
            res(tP)
        })
    }
 
    postCommentTimeline(mensaje:string,id:string,d?:{ruta:string}){
        return new Promise((res,rej)=>{
            if(mensaje!=undefined){          
                const g :TimelinePostCommentInterface={
                    user   : {
                        uid:this.jmyService.jmyPerfil.perfil.uid,
                        name  : this.jmyService.jmyPerfil.perfil.displayName,
                        avatar: this.jmyService.jmyPerfil.perfil.photoURL
                    },
                    time   : this.jmyService.jmyFn.fnT.tiempo(),
                    timestamp   : String(Date.now()),
                    message: mensaje,
                    type    : 'post',
                    like    : '0',
                };
                console.log(this.configOn.ruta+id+'/comentarioDest/');
                this.jmyService.dbRealTime.object(this.configOn.ruta+id+'/comentarioDest/').set(g).then(rA=>{
                    console.log('ComDes',rA);
                    this.jmyService.dbRealTime.list(this.configOn.ruta+id+'/comments').push(g).then(rA=>{
                        res(g)
                    }).catch(r=>{rej({r,g})});
                }).catch(r=>{rej({r,g})});
          
            }
        })
    }

  

}

import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { jmyConfig } from 'environments/environment';
import { Observable,BehaviorSubject , Subject} from 'rxjs';
import { navInterface, 
    NotificarInterface,
    configFnInterface,
    ActividadesInterface,
    TimelinePostConfigInterface,
    ArchivosConfigInterface,
    ArchivosSolicitarInterface
    } from '../interfaces/services';
import { States } from '../admin/states';
import { JmyPerfil } from './JmyPerfil';
import { HttpClient } from '@angular/common/http';
import { navigation } from 'app/navigation/navigation';
import { log } from 'util';
import { Router }  from '@angular/router'
import { MatSnackBar } from '@angular/material/snack-bar';
import { group } from '@angular/animations';
import { Title }     from '@angular/platform-browser';
import { take, takeUntil } from 'rxjs/operators';
import { ftruncate } from 'fs';

import { ToastrService } from 'ngx-toastr';

@Injectable()
export class JmyFn {
    
    private _unsubscribeAll: Subject<any>;
    public contadores:BehaviorSubject<object>;
    public notificacionesAlert:BehaviorSubject<object>;
    public notificacionesLista:BehaviorSubject<object[]>;
    public notificacionesListaContador:BehaviorSubject<string>;
    private config: navInterface = jmyConfig;
    private usersRef: AngularFireList<any>; // Reference to users list, Its an Observable
    private userRef: AngularFireObject<any>; // Reference to user object, Its an Observable too
    private CNF:configFnInterface;
    lT:AngularFireList<any>;

    private configArchivos:ArchivosConfigInterface;
    private archivosQ:any;
    configuracionArchivosQ:any;
    configuracionArchivos:BehaviorSubject<ArchivosConfigInterface>;
    configuracionArchivosiF:BehaviorSubject<''>;
    botonEnEsperaArchivos:BehaviorSubject<boolean>;
    
    treeArchivos:BehaviorSubject<{tree:string[],url_iframe:object}>;


    constructor(
        private db: AngularFireDatabase,
        private http: HttpClient,
        private router: Router,
        private jmyPerfil: JmyPerfil,
        private titleService: Title,
        private toastr: ToastrService
        ) {
            
        // Set the private defaults
        this._unsubscribeAll = new Subject();
            this.configArchivos=(this.jmyPerfil.perfil!=undefined)?{
                ruta:'documentos/',
                modulo:'perfil',
                raiz:'privado/'+this.jmyPerfil.perfil.uid+'/',
                permiso:'4',
              }:{
                ruta:'',
                modulo:'',
                raiz:'',
                permiso:'',
              };
            this.botonEnEsperaArchivos= new BehaviorSubject(true); 
            this.configuracionArchivos= new BehaviorSubject(this.configArchivos);
            this.configuracionArchivosiF= new BehaviorSubject('');
            this.notificacionesAlert=new BehaviorSubject ({}) ;
            this.notificacionesLista=new BehaviorSubject ([]) ;
            this.notificacionesListaContador=new BehaviorSubject ('0') ;
            this.contadores=new BehaviorSubject ({}) ;
            this.treeArchivos=new BehaviorSubject ({tree:[],url_iframe:{}}) ;
            this.CNF={ruta:{
                sistema : '/_SyS/'+jmyConfig.app.eid+'/',
                notificaciones : 'notificaciones/lista/',
                notificacionesPerfil : 'notificaciones/lista/',
                notificacionesGrupo : 'notificaciones/grupos/',
            }};
            //console.log(this.CNF);
            this.listaNotificaciones().then();
            this.configuracionArchivos  .pipe(takeUntil(this._unsubscribeAll)).subscribe(conf=>{
                let p={...conf,
                    eid:jmyConfig.app.eid,
                    key:jmyConfig.app.key,
                    uid:(this.jmyPerfil.perfil!=undefined)?this.jmyPerfil.perfil.uid:'0'
                }
                this.configuracionArchivosQ=p;
            })
    }
   /*ngOnInit():void{

    }
    ngOnDestroy(): void{
        
    }*/
    getTimeline(uid:string,ruta:string,d?:TimelinePostConfigInterface): Promise<any[]>
    {
        return new Promise((resolve, reject) => {
            const r='JmyS/'+jmyConfig.app.eid;
            const k=this.db.list(r).push({
                api:"timeline",
                q:{ruta:ruta||'',uid:uid||'',d:d||{}}
            }).key;
           // console.log('getTimeline',k);
        })
    }
    private grupoNotificar(grupo:string=null,id:string=null,uid:string[]=null,borrar:boolean=false){
        return new Promise((res,rej)=>{
            if(grupo!=undefined){
                const q=this.db.object(this.CNF.ruta.notificacionesGrupo+grupo).valueChanges().pipe(takeUntil(this._unsubscribeAll)).subscribe(r=>{
                            q.unsubscribe();
                        let u=r||{};
                        if(u[id]==undefined)
                            u[id]=[];   
                        uid.forEach(_uid => {
                            if(borrar)
                                u[id].splice(u[id].indexOf(_uid),1)
                            else
                                if(!u[id].includes(_uid))
                                    u[id].push(_uid)
                        });
                        this.db.object(this.CNF.ruta.notificacionesGrupo+grupo).set(u).then(qR=>{ })
                        res(u[id]);
                    });
            }else{
                const er = {error:'Grupo Notificación: Se requiere GRUPO para ver usuarios y GRUPO y UID[] para agregar o BORRAR=true para borrar usuarios'};
                console.error(er);
                rej(er)
            }
        })
    }
    archivos(d:ArchivosConfigInterface=this.configArchivos):Promise<any>{
        return new Promise((res,rej)=>{
            this.botonEnEsperaArchivos.next(true);
            this.archivosQ=this.http.post(jmyConfig.app.servidor+'archivos', this.configuracionArchivosQ)
            .pipe(take(1),takeUntil(this._unsubscribeAll)).subscribe(r=>{
                this.archivosQ.unsubscribe();
                console.log(r,{
                    tree:r['o']['tree'],
                    url_iframe:r['o']['url_iframe']
                });
                if(r['error']!=''){
                    if(r['error']['mensaje']!=''){
                        this.toastr.error(r['error'],'Aceeso restringido')
                        rej(r['error'])}
                }
               // if(r['error']==undefined||r['error']=='')
                this.treeArchivos.next({
                    tree:r['o']['tree']||[],
                    url_iframe:r['o']['url_iframe']||{}
                });
                
                this.botonEnEsperaArchivos.next(false);
                if(r!=undefined){
                    
                    if(r['o']['id']!=''){
                        res(r['o']);
                    }else{
                        if(r['error']['mensaje']!='')
                            rej(r['error'])
                    }
                }else{
                    rej({mensaje:'Error al solicitar sistema de archivos'})
                }
            })
        })
    }
    notificar(d:NotificarInterface){
        return new Promise((res,rej)=>{
            if(d.app!=undefined&&d.titulo!=undefined&&d.detalle!=undefined&&d.idNotificacion!=undefined){
                d.autor={
                    displayName:this.jmyPerfil.perfil.displayName,
                    email:this.jmyPerfil.perfil.email,
                    uid:this.jmyPerfil.perfil.uid,
                    photoURL:this.jmyPerfil.perfil.photoURL
                }
                d.estado='3';
                d.timestamp= this.fnT.tiempo();
                d.fecha = String(Date.now());
                d.icon = (d.icon!=undefined)?d.icon:'bubble_chart'; 
                this.grupoNotificar(d['grupo'],d.idNotificacion,[d.autor.uid]).then(usN=>{
                    console.log('notificar',d);
                    console.log('Usuarios a notificar',usN);
                    const TusN:any=usN;
                    if(d.usuarios==undefined)
                        d.usuarios=[];
                    Object.assign(TusN,d.usuarios).forEach(us => {
                        if(us!=this.jmyPerfil.perfil.uid){
                            const rL = 'private/perfiles/'+us+'/notificaciones';
                            const q=this.db.list(rL, 
                                ref => ref.orderByChild('idNotificacion').equalTo(d.idNotificacion)
                            ).snapshotChanges().subscribe(l=>{
                                q.unsubscribe();
                                
                                console.log(l);
                                if(l.length!=0)
                                    this.not(d,us,l[0]['key']).then(r=>{});
                                else
                                    this.not(d,us).then(r=>{});
                            });
                        }else{
                            this.actividades({
                                detalle:d.detalle,
                                url:d.url || null,
                                nav:d.nav || null,
                                icon:d.icon,
                                fecha:d.fecha,
                                timestamp:d.timestamp,
                            }).then(r=>{
                                console.log(r);
                            })
                        }
                    });
                });
            }else{
                const er = {error:'Notificar: Se requiere app, titulo, detalle'};
                console.error(er);
                rej(er)
            }
        })
    }
    private not(d:any,u:string,id:string=null){
        return new Promise((res,rej)=>{
            const rL = 'private/perfiles/'+u+'/notificaciones/';
            console.log(rL,d,id);
            if(id==undefined)
                this.db.list(rL).push(d).then(r=>{});
            else
               this.db.list(rL).update(id,d).then(r=>{});
        })
    }
    actividades(d:ActividadesInterface){
        return new Promise((res,rej)=>{
            this.db.list(this.jmyPerfil.rutaPrivada+'perfil/actividad').push(d);
            res(d);
         })
    }
    
    fnT = {
        t:new Date(),
        dosDigitos:(numero:number):string=>{
            return (numero<10)?'0'+String(numero):String(numero);
        },
        tiempo:():string=>{
            const t = new Date();
            return String(t.getFullYear()+'-'+this.fnT.dosDigitos(t.getMonth()+1)+'-'+this.fnT.dosDigitos(t.getDay()+1)+' '+this.fnT.dosDigitos(t.getHours())+':'+this.fnT.dosDigitos(t.getMinutes())+':'+this.fnT.dosDigitos(t.getSeconds())+'-06:00')}
    };
    funcionesTiempo=this.fnT;

    amigos(uid:string=this.jmyPerfil.perfil.uid){        
        return new Promise((res,rej)=>{
            const rL = this.jmyPerfil.rutaPublicaAmigos;
            this.db.list(rL,ref=>ref.orderByValue()).snapshotChanges().subscribe(r=>{
                const qR:any[]=r.map(c => ({ key: c.payload.key, ...c.payload.val() }));
                res(qR);
            });
        });
    }



    listaNotificaciones(){
        return new Promise((res,rej)=>{
            if(this.jmyPerfil.perfil!=undefined){
                const rL = 'private/perfiles/'+this.jmyPerfil.perfil.uid+'/notificaciones/';
                this.db.list(rL,ref=>ref.orderByValue()).snapshotChanges().subscribe(r=>{
    //              console.log(rL,r);


                    const qR:any[]=r.map(c => ({ key: c.payload.key, ...c.payload.val() }));

                    let cR:string[]=[];
                    qR.forEach(e => {
    //                    console.log(e);
                        
                        if(e['estado']=='3')
                            cR.push(e['key'])
                    });
        //            console.log(cR);

                    
                    this.notificacionesListaContador.next(String(cR.length));

                    
        //          console.log(cR,cR.length,qR);
                    this.notificacionesLista.next(qR);
                    res(qR);
                })
            }else{
                this.notificacionesListaContador.next('0');
                this.notificacionesLista.next([]);
                res([])
            }
        })
    }






    verNotificacion(id:string){
        return new Promise((res,rej)=>{
            const rL = 'private/perfiles/'+this.jmyPerfil.perfil.uid+'/notificaciones/'+id;
            const q=this.db.object(rL).valueChanges().subscribe(r=>{
                q.unsubscribe();
                console.log(r);
                
                let t:any=r;
                t.estado=2;
                this.db.object(rL).set(t);
                if(t.nav!=undefined){
                    console.log(t.nav);
                    
                    this.router.navigate([t.nav]);
                }
            })
        })
    }
    contador(nombre:string='',numero:number=null){
        return new Promise((res,rej)=>{
            if(nombre!=''){
                const q = this.db.object(this.CNF.ruta.sistema+'contadores/'+nombre).valueChanges().subscribe(r=>{
                    q.unsubscribe();
                    console.log(r);
                    let c:number=Number(r)||0;
                    if (numero!=undefined) {
                        c=c+((numero==0)?1:numero);
                        this.contadorA(nombre,c).then(rC=>{
                            res(c);
                        }).catch(er=>{
                            q.unsubscribe();console.error(er);rej(er);})
                    }else{
                        res(c);
                    }
                });
            }
        })
    }

    private contadorA(nombre:string,numero:number){
        return new Promise((res,rej)=>{
            if(nombre!=''){
                let q = this.db.object(this.CNF.ruta.sistema+'contadores/'+nombre).set(numero).then(r=>{
                    console.log(r);
                    res(numero)
                },er=>{console.error(er);rej(er);});
            }
        })

    }
    private contadorV(nombre:string){}
    public titulo(titulo: string) {
        this.titleService.setTitle( titulo );
      }
 /**
     * On destroy
     */
    ngOnDestroy(): void
    {
      
        this.treeArchivos.complete();
        this.botonEnEsperaArchivos.complete();
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

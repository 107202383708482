import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { JmyService } from '../../../concomsis/jmy/jmy.service';
import { infoPerfilInterface, chatUserInterface } from '../../../concomsis/jmy/interfaces/services';
import { FuseUtils } from '@fuse/utils';

@Injectable()
export class ChatService implements Resolve<any>
{
    contacts: any[];
    listaStatus: string[];
    chats: any[];
    user: any;
    status: BehaviorSubject<any>;
    onChatSelected: BehaviorSubject<any>;
    onContactSelected: BehaviorSubject<any>;
    onChatsUpdated: Subject<any>;
    onUserUpdated: Subject<any>;
    onLeftSidenavViewChanged: Subject<any>;
    onRightSidenavViewChanged: Subject<any>;

    rutaChat:string='/chat/'
    
    perfil:infoPerfilInterface;
    
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private jmyService: JmyService
    )
    {
        // Set the defaults
        this.status = new BehaviorSubject(null);
        this.onChatSelected = new BehaviorSubject(null);
        this.onContactSelected = new BehaviorSubject(null);
        this.onChatsUpdated = new Subject();
        this.onUserUpdated = new Subject();
        this.onLeftSidenavViewChanged = new Subject();
        this.onRightSidenavViewChanged = new Subject();
        
        this.perfil=this.jmyService.jmyPerfil.perfil;
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getContacts(),
                this.getChats(),
                this.getUser(),
                
            ]).then(
                ([contacts, chats, user]) => {
                    this.contacts = contacts;
                    this.chats = chats;
                    this.user = user;
                    resolve();
                },
                reject
            );
        });
    }
    loadContacts(): Promise<any> | any
    {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getContacts(),
                this.getUser()
            ]).then(
                ([contacts, user]) => {
                    this.contacts = contacts;
                    this.user = user;
                    resolve();
                },
                reject
            );
        });
    }
    /**
     * Get chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    getChat(contactId): Promise<any>
    {
            const list:Array<any>=this.user.chatList;
            const chatItem = list.find((item) => {
                return item.contactId === contactId;
            });

            // Create new chat, if it's not created yet.
            if ( !chatItem )
            {
                this.createNewChat(contactId).then((newChats) => {
                    this.getChat(contactId);
                });
                return;
            }

            return new Promise((resolve, reject) => {
                const ruta:string = this.jmyService.jmyPerfil.rutaPrivada+'/chat/';

                this.jmyService.dbRealTime.object(this.jmyService.jmyPerfil.rutaPublicaAmigos+contactId).valueChanges().subscribe(amigo=>{
                    
                    let chatData = {
                        chatId : contactId,
                        dialog : [],//chat.dialog,
                        contact:   {
                            id    : contactId,
                            name  : amigo['displayName'],
                            avatar: amigo['photoURL'],
                            status:'offline',
                            mood  : ''
                        },//chatContact
                    };
                    this.onChatSelected.next({...chatData});
                    this.jmyService.dbRealTime.object(ruta+'chats/'+contactId+'/dialog').valueChanges().subscribe(CHT=>{
                       // console.log(chats);
                        let chats:Object = CHT||[];
                        let chat:Object = chats||{};
                        let chatSalida:any=[];
                        Object.keys(chats).forEach(e => {
                            chatSalida.push({
                                who:chats[e]['who'],
                                message:chats[e]['message'],
                                time:chats[e]['time'],
                            })
                        });
                       // console.log(chatSalida);
                        
                        chatData.dialog =(chat)?chatSalida:[];
                        this.onChatSelected.next({...chatData});
                        resolve(chatSalida)
                    });    
                    this.status.subscribe(status=>{
                        if(Object.keys(status).includes(contactId)){
                            chatData.contact.status = status[contactId]
                            this.onChatSelected.next({...chatData});
                        }
                        
                    });               
                });



/*
                this._httpClient.get('api/chat-chats/1725a680b3249760ea21de52')
                    .subscribe((response: any) => {
                        const chat = response;
    console.log('chat----',chat);

                        const chatContact = this.contacts.find((contact) => {
                            return contact.id === contactId;
                        });

                        const chatData = {
                            chatId : chat.id,
                            dialog : chat.dialog,
                            contact: chatContact
                        };

                        this.onChatSelected.next({...chatData});

                    }, reject);
*/
            });

    }
    
    fnT:any = {
        t:new Date,
        dosDigitos:(numero:number):string=>{
            return (numero<10)?'0'+String(numero):String(numero);
        },
        tiempo:(tiempo,sub:boolean=false)=>{
            let ti:string=(typeof tiempo == "string")?tiempo:'';
            return (sub)?ti+'-06:00':ti.slice(1)+'-06:00';
        }
    };
    /**
     * Create new chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    createNewChat(contactId): Promise<any>
    {
        return new Promise((resolve, reject) => {

            const contact = this.contacts.find((item) => {
                return item.id === contactId;
            });

            const chatId = FuseUtils.generateGUID();

            const chat = {
                id    : chatId,
                dialog: []
            };

            const chatListItem = {
                contactId      : contactId,
                id             : chatId,
                lastMessageTime: '2017-02-18T10:30:18.931Z',
                name           : contact.name,
                unread         : null
            };

            this.user.chatList.push(chatListItem);

            // Add new chat list item to the user's chat list
            const nuevoChatList = this.jmyService.dbRealTime.object(this.jmyService.jmyPerfil.rutaPrivada+'/chat/user');
            const nuevoChatListReceptor = this.jmyService.dbRealTime.object(this.jmyService.jmyPerfil.rutaPrivada+'/chat/user');

            nuevoChatList.set(this.user).then(r=>{
              //  console.log('guardar lista chat nuevo', r);

                const nuevaSalaChat = this.jmyService.dbRealTime.list(this.jmyService.jmyPerfil.rutaPrivada+'/chat/chats/'+contactId+'/dialog/');
                let t = new Date();

                const fnT = {
                    dosDigitos:(numero:number):string=>{
                        return (numero<10)?'0'+String(numero):String(numero);
                    },
                    tiempo:()=>{
                        return t.getFullYear()+'-'+fnT.dosDigitos(t.getMonth())+'-'+fnT.dosDigitos(t.getDay())+'T'+fnT.dosDigitos(t.getHours())+':'+fnT.dosDigitos(t.getMinutes())+':'+fnT.dosDigitos(t.getSeconds())+'.'+t.getMilliseconds()+'-06:00'}
                };
                nuevaSalaChat.push({ 
                    who    : contactId,
                    message: 'Conectado con '+chatListItem.name ,
                    time   : fnT.tiempo()
                });


            }).catch(r=>{
                console.error('guardar lista chat nuevo', r);
            });


            // Post the created chat
            this._httpClient.post('api/chat-chats', {...chat})
                .subscribe((response: any) => {

                    // Post the new the user data
                    this._httpClient.post('api/chat-user/' + this.user.id, this.user)
                        .subscribe(newUserData => {

                            // Update the user data from server
                            this.getUser().then(updatedUser => {
                               // console.log(updatedUser);
                                
                                this.onUserUpdated.next(updatedUser);
                                resolve(updatedUser);
                            });
                        });
                }, reject);
        });
    }

    /**
     * Select contact
     *
     * @param contact
     */
    selectContact(contact): void
    {
        this.onContactSelected.next(contact);
    }

    /**
     * Set user status
     *
     * @param status
     */
    setUserStatus(status): void
    {
        this.user.status = status;
    }

    /**
     * Update user data
     *
     * @param userData
     */
    updateUserData(userData): void
    {
        console.log(userData);
        const query = this.jmyService.dbRealTime.object(this.rutaChat+this.perfil.uid+'user');

        query.set(userData).then(r=>{
           // console.log('then',r);
            
        }).catch(err=>{
            console.error(err);
            
        })
        
        this._httpClient.post('api/chat-user/' + this.user.id, userData)
            .subscribe((response: any) => {
                    this.user = userData;
                }
            );
    }

    /**
     * Update the chat dialog
     *
     * @param chatId
     * @param dialog
     * @returns {Promise<any>}
     */
    updateDialog(chatId, dialog,message): Promise<any>
    {
        return new Promise((resolve, reject) => {

            
            const SalaChat = this.jmyService.dbRealTime.list(this.jmyService.jmyPerfil.rutaPrivada+'/chat/chats/'+chatId+'/dialog/');
          
            let messageOut:object={ 
                'who'    : message['who'],
                'message': message['message'] ,
                'time'   : this.fnT.tiempo(message['time'],true)
            }
            SalaChat.push(messageOut);

            const SalaChatReceptor = this.jmyService.dbRealTime.list(this.jmyService.jmyPerfil.rutaPrivadaRoot+chatId+'/chat/chats/'+message['who']+'/dialog/');
            
            SalaChatReceptor.push(messageOut);

            resolve(dialog);

            const newData = {
                id    : chatId,
                dialog: dialog
            };
/*
            this._httpClient.post('api/chat-chats/' + chatId, newData)
                .subscribe(updatedChat => {
                    
                    console.log(updatedChat,chatId, dialog,message);
                    
                    resolve(updatedChat);
                }, reject);
                */
        });
    }

    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */
    getStatus():Promise<any>{
        return new Promise((res,rej)=>{
            let sO:object=[];
            //this.status.next();
            this.jmyService.dbRealTime.object('status').valueChanges().subscribe(sR=>{
                let t:any=sR;
                Object.keys(t).forEach(e => {
                    if(this.listaStatus.includes(e))
                        if(t[e]['timestamp']>Number(Date.now())-6000)
                            sO[e]=t[e]['status']
                        else
                            sO[e]='offline'
                    });
                    this.status.next(sO);
                    res(sO)
            });
        })
    }
    getContacts(): Promise<any>
    {        
        return new Promise((resolve, reject) => {
            if(this.jmyService.jmyPerfil.rutaPublicaAmigos)
            this.jmyService.dbRealTime.object(this.jmyService.jmyPerfil.rutaPublicaAmigos).valueChanges().subscribe(r=>{
                
//                console.log(r);
                    let out = [];
                    const lS= this.listaStatus;
                    this.listaStatus = [];
                    if(r!=undefined)
                        Object.keys(r).forEach(contacto => {
                            //console.log(contacto);
                            
                            this.listaStatus.push(r[contacto]['uid']);
                            out.push( {
                                id    : r[contacto]['uid'],
                                name  : r[contacto]['displayName'],
                                avatar: r[contacto]['photoURL'],
                                status: 'offline',
                                mood  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                            })
                        });
        //              console.log(out);
                        if(lS!=this.listaStatus && r)
                            this.getStatus();
                    resolve(out);
            });
            else
                resolve([]);

            /*this._httpClient.get('api/chat-contacts')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);*/
        });
    }

    /**
     * Get chats
     *
     * @returns {Promise<any>}
     */
    getChats(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/chat-chats')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    getUser(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            
            if(this.jmyService.jmyPerfil.rutaPrivada!=undefined)
                this.jmyService.dbRealTime.object(this.jmyService.jmyPerfil.rutaPrivada+'/chat/user').valueChanges().subscribe(r=>{
                    const rT:any=r;
                    const temp:chatUserInterface=rT;
                    this.user=(temp)?temp: 
                        {
                            id      : this.perfil.uid,
                            name    : this.perfil.displayName,
                            avatar  : this.perfil.photoURL,
                            status  : (temp)?temp.status:'online',
                            mood    : (temp)?temp.mood:'Hola! estoy disponible.',
                            chatList: (temp)?temp.chatList:[]
                        };
                        resolve(this.user);
                    
                })
            else
                resolve([])
            /*this._httpClient.get('api/chat-user')
                .subscribe((response: any) => {
                    resolve(response[0]);
                }, reject);*/
        });
    }
}

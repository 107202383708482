import { emit } from 'cluster';
import { DateFormatterParams } from 'angular-calendar';
import { StoreConfig } from '@ngrx/store/src/store_module';
import { timestamp } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';
import { MatChipInputEvent } from '@angular/material/chips';
export interface ServicesInterface {
}
export class UserInterface {
    uid:string
    photoURL:string
    displayName:string
    fecha:string;
    timestamp:string
    state?:string
    email?:string
    phone?:string
    constructor(r){
        r=r||{};
        this.uid = r.uid || r.key || ''
        this.photoURL = r.photoURL || r.avatar ||''
        this.displayName = r.displayName || r.name|| ''
        this.timestamp = r.timestamp ||''
        this.fecha = r.fecha = String(Date.now())
        this.state = r.state ||''
        this.email = r.email ||''
        this.phone = r.phone ||''
    }
}


export class ScrumboardBoardsCardInterface
{
    id: string;
    name: string;
    description: string;
    idAttachmentCover: string;
    idMembers: string[];
    idLabels: string[];
    attachments: any[];
    subscribed: boolean;
    checklists: any[];
    checkItems: number;
    checkItemsChecked: number;
    comments: any[];
    activities: any[];
    due: string;

    /**
     * Constructor
     *
     * @param card
     */
    constructor(card)
    {
        //console.log(card);
        
        this.id = card.id || FuseUtils.generateGUID();
        this.name = card.name || 'Nueva tarjeta';
        this.description = card.description || this.name;
        this.idAttachmentCover = card.idAttachmentCover || 'ejemplo-cover';
        this.idMembers = card.idMembers || [];
        this.idLabels = card.idLabels || [];
        this.attachments =  ((this.idAttachmentCover=='ejemplo-cover')?[  {
            id  : 'ejemplo-cover',
            name: 'ejemplo-cover.jpg',
            src : '/assets/images/backgrounds/dark-material-bg-sm.jpg',
            time: 'Origen',
            type: 'image'
        }]:card.attachments||[]);
        this.subscribed = card.subscribed || true;
        this.checklists = card.checklists || [];
        this.checkItems = card.checkItems || 0;
        this.checkItemsChecked = card.checkItemsChecked || 0;
        this.comments = card.comments || [];
        this.activities = card.activities || [];
        this.due = card.due || '';
    }
}
export class ScrumboardBoardsInterface {
    id      : string
    idEspacio : string
    name    : string
    uri     : string
    settings: {
        color          : string
        subscribed     : boolean
        cardCoverImages: boolean
    }
    lists   : object[]
    cards   :        
        {
            id               :string
            name             :string
            description      :string
            idAttachmentCover:string
            idMembers        :string[]
            idLabels         : []
            attachments      : 
                {
                    id  :string
                    name:string
                    src :string
                    time:string
                    type:string
                }[]
            subscribed       : boolean,
            checklists       : [],
            checkItems       : number,
            checkItemsChecked: number,
            comments         : [],
            activities       : [],
            due              : string
        }[]
    
    members : 
        {
            id    :string
            name  :string
            avatar:string
        }[]
    labels  : 
        {
            id   :string
            name :string
            color:string
        }[]

    constructor(r){        
        this.id   = r.id || FuseUtils.generateGUID()
        this.name = r.name || 'Nuevo tablero'
        this.idEspacio = r.idEspacio || this.id
        this.uri  = FuseUtils.handleize(this.name)
        this.settings = r.settings || {
            color          : (r.settings!=undefined)?r.settings.color:'fuse-dark',
            subscribed     : (r.settings!=undefined)?r.settings.subscribed:false,
            cardCoverImages: (r.settings!=undefined)?r.settings.cardCoverImages:true
        };
        this.lists   = r.lists || []
        this.cards   = r.cards || []
        this.members = r.members || []
        this.labels  = r.labels || []
    }
}
export class ScrumboardEspaciosInterface {
    id: string;
    nombre:string;
    administradores:UserInterface[];
    compartido:UserInterface[];
    fecha:string;
    timestamp:string;
    estado:string;
    alertas:string;
    constructor(r){ 
        this.id = r.id || FuseUtils.generateGUID()
        this.nombre = r.nombre||'Nuevo espacio '
        this.administradores = r.administradores||[]
        this.compartido = r.compartido || []
        this.fecha = r.fecha || String(Date.now())
        this.timestamp = r.timestamp || ''
        this.estado = r.estado || ''
        this.alertas = r.alertas ||  ''
    }    
}
export interface ArchivosSolicitarInterface {
    eid:string
    key:string
    uid:string
    dia:string
    timestamp:string
    time:string
    token:string
}
export interface ArchivosConfigInterface {
    ruta:string
    modulo?:string
    lista?:string
    raiz?:string
    permiso?:string
}


/* TIMELINE API */


export class TimelinePostConfigInterface {
    ruta?:string
    sala?:string
    rl?:string
    redireccionar?:string
    despliegue?:string[]
    constructor(d?){
        const o = d||{};
        this.ruta = o.ruta || '';
        this.sala = o.sala || '';
        this.ruta = this.ruta.replace('//','/');
        this.sala = this.sala.replace('//','/');
        this.rl = (this.ruta+this.sala).replace('//','/');
        this.redireccionar = o.redireccionar || '';
        this.despliegue = o.despliegue || [];
    }
}
export class TimelinePostCommentUserInterface {
    uid:string
    name  : string
    avatar: string
    constructor(d?){
        d=d||{};
        this.uid = d.uid || '';
        this.name = d.name || '';
        this.avatar = d.avatar || '';
    }
}
export class TimelinePostCommentInterface {
    user   : TimelinePostCommentUserInterface
    timestamp   : string
    time   : string
    message: string
    type    ?: string
    like    ?: string
    likeU    ?: TimelinePostActionsUserInterface[]
    comments?:TimelinePostCommentInterface []
    media   ?: {
        type   : string
        preview: string
    }[]
    constructor(d?){
        d=d||{};
        this.user = new TimelinePostCommentUserInterface(d.user);
        this.timestamp = d.timestamp || '';
        this.time = d.time || '';
        this.message = d.message || '';
        this.type = d.type || '';
        this.like = d.like || '';
        this.likeU = d.likeU || d.likeU.map(m=>{return new TimelinePostActionsUserInterface(m)})||[];
        this.comments = d.comments || '';
        this.media = d.media || [];
    }
}

export class TimelinePostStatesUserInterface {
    state?:'start'|'on'|'off'|'delete'
    constructor(d?){
        d=d||{};
        this.state=d.state || 'on'; 
    }
}

export class TimelinePostMediaInterface {
    type:'image/jpg'|'image/gif'|'video/mp4'|'video/webm'|'video/youtube'|''
    preview : string
    constructor(d){
        d=d||{};
        this.type=d.type || ''; 
        this.preview=d.preview || ''; 
    }
}

export class TimelinePostActionsUserInterface {
    uid:string
    photoURL:string
    displayName:string
    timestamp:string
    state?:TimelinePostStatesUserInterface
    type?:'like'|'share'
    constructor(d){
        d=d||{};
        this.uid = d.uid || '';
        this.photoURL = d.photoURL || '';
        this.displayName = d.displayName || '';
        this.timestamp = d.timestamp || '';
        this.state = new TimelinePostStatesUserInterface(d.state);
        this.type = d.type || 'like';
    }
}

export class TimelinePostIndexSalasInterface {
    id:string
    titulo:string
    config:TimelinePostConfigInterface
    usuarios:UserInterface[]
    usuariosLeido:string[]
    usuariosNotifiaciones:string[]
    constructor(d){
        console.log(d);
        d=d||{};
        this.id = d.id||d.key||'';
        this.titulo = d.titulo || d.title ||'';
        this.config = new TimelinePostConfigInterface(d.config);
        this.usuarios = d.usuarios ||'';
        this.usuariosLeido = d.usuariosLeido ||'';
        this.usuariosNotifiaciones = d.usuariosNotifiaciones ||'';
    }


    /*
    message
title
ruta
sala
tags
type
state
key
like
banderaLike
likeU
likeUDisplay
share
config
timestamp
time
comentarioDest
view
viewU
media
user
article
proyecto
comments
    */
}

export class TimelinePostIndexInterface {
    id:string
    titulo  ?: string
    descripcion?: string
    salas ?: TimelinePostIndexSalasInterface[]
    modulo:{
        permiso:string
        modulo:string
    }[]
    media   ?: TimelinePostMediaInterface
    constructor(d){
        d=d||{};
        this.id=d.id||FuseUtils.generateGUID();
        this.titulo=d.titulo||'';
        this.descripcion=d.descripcion||'';
        this.salas=d.salas||[];
        this.modulo=d.modulo||[];
        this.media = new TimelinePostMediaInterface(d.media);   
    }
}
export class TimelinePostOGInterface {
    title   ?: string
    subtitle?: string
    excerpt ?: string
    media   ?: TimelinePostMediaInterface
    constructor(d){
        d=d||{};
        this.title = d.title || '';   
        this.subtitle = d.subtitle || '';
        this.excerpt = d.excerpt || ''; 
        this.media = new TimelinePostMediaInterface(d.media);   
    }
}

export class TimelinePostInterface {
    message : string
    title ?: string
    ruta ?: string
    link ?: string
    sala ?: string
    tags    ?:string[]
    type    ?: 'post'|'article' | 'proyecto' | 'media'
    state?:TimelinePostStatesUserInterface
    key    ?: string
    like    ?: number
    banderaLike    ?: string
    likeU    ?: TimelinePostActionsUserInterface[]
    likeUDisplay    ?: TimelinePostActionsUserInterface[]
    share   ?: number
    config?:TimelinePostConfigInterface
    timestamp    ?: string
    time    ?: string
    comentarioDest    ?: TimelinePostCommentInterface
    view    ?: string[]
    viewU    ?: TimelinePostActionsUserInterface[]
    media   ?: TimelinePostMediaInterface
    user   : TimelinePostCommentUserInterface
    article ?: TimelinePostOGInterface
    proyecto ?: TimelinePostOGInterface
    comments?:TimelinePostCommentInterface []

    constructor(d?){
        d=d||{};
        this.message= d.message || '';
        this.title= (d.title)?d.title.slice(0,45): d.message ? String(d.message).replace(/<[^>]+>/gm, '').slice(0,45) : '';
        this.ruta= d.ruta || '';
        this.link = d.link || '';
        this.sala= d.sala || '';
        this.state = new TimelinePostStatesUserInterface(d.state);
        this.tags= d.tags || [];
        this.type= d.type || 'article';
        this.key= d.key || FuseUtils.generateGUID()+'__'+FuseUtils.generateGUID();
        this.like= (d.likeU)?d.likeU.length:0;
        this.banderaLike= d.banderaLike || '';
        this.likeU= (d.likeU)?d.likeU.map(m=>{return new TimelinePostActionsUserInterface(m)}):[];
        this.likeUDisplay= (d.likeUDisplay)?d.likeUDisplay.map(m=>{return new TimelinePostActionsUserInterface(m)}):[];
        this.share= d.share || 0;
        this.config=  new TimelinePostConfigInterface(d.config);
        this.timestamp= d.timestamp || '';
        this.time= d.time || String(Date.now());
        this.comentarioDest= d.comentarioDest || '';
        this.view= d.view || '';
        this.viewU= (d.viewU)?d.viewU.map(m=>{return new TimelinePostActionsUserInterface(m)}):[];
        this.comments = (d.comments)?d.comments.map(m=>{return new TimelinePostCommentInterface(m)}) :[];
        this.user = new TimelinePostCommentUserInterface(d.user);
        this.media = (d.media) ? new TimelinePostMediaInterface(d.media):null;
        this.article = (d.article) ? new  TimelinePostOGInterface(d.article):null;
        this.proyecto = (d.proyecto) ? new  TimelinePostOGInterface(d.proyecto):null;
    }
}
export interface configFnInterface {
    ruta:{
        sistema:string,
        notificaciones:string,
        notificacionesPerfil:string,
        notificacionesGrupo:string
    }
}
export interface NotificarInterface {
    titulo:string,
    detalle:string,
    app:string,
    grupo:string,
    idNotificacion:string,
    url?:string,
    nav?:string,
    usuarios?:string[],
    icon?:string,
    grupoIds?:string[],//sistema
    estado?:string, //sistema
    autor?:listadoPerfilPublicoInterface,//sistema
    fecha?:string,//sistema
    timestamp?:string//sistema
}
export interface ActividadesInterface {
    detalle:string,
    url?:string,
    nav?:string,
    icon?:string,
    fecha?:string
    timestamp?:string
}

export interface ContactosListMinInterface {
    photoURL:string,
    email:string,
    displayName:string,
    id:string,
    url?:string,
}

export interface ContactosInfoInterface {
    id:string,
    uid:string,
    name:string,
    email:string,
    lastName?:string,
    avatar?:string,
    nickname?:string,
    company?:string,
    jobTitle?:string,
    phone?:string,
    address?:string,
    birthday?:string,
    notes?:string,
}

export interface DbVerOutInterface {
    header?:object,
    out:any
}

export interface DbVerInterface {
    db?:string,
    area?:string,
    ruta?:string,
    status?:string,
    app?:string,
    tabla?:string,
    id?:string,
    idGrupo?:string,
    lista?:boolean,
}

export interface DbStatesInterface {
    no_save?:string,
    save?:string,
    new?:string,
    cache?:string,
    private?:string,
    public?:string,
    group?:string,
    db?:string,
    table?:string
}
export interface DbStatusInterface {
    ruta?:string,
    idGuardar?:string,
    status?:string,
    time?:string,
    db?:string,
    app?:string,
    tabla?:string,
    id?:string,
    registro?:string,
    
    
    
}

export interface DbVerOtInterface {
    query?:string,
    cache?:any,
    lista?:any
}

export interface DbGuardarInterface {
    db?:string,
    area?:string,
    ruta?:string,
    status?:Array<DbStatusInterface>,
    app?:string,
    tabla?:string,
    id?:string,
    idGrupo?:string,
    guardar?:object,
    lista?:boolean,
}

export interface chatUserInterface {
    id      :string,
    name    :string,
    avatar  :string,
    status  :string,
    mood    :string,
    chatList:Array<chatUserChatListInterface> 
}

export interface chatUserChatListInterface {
    id             :string,
    contactId      :string,
    name           :string,
    unread         :number,
    lastMessage    :string,
    lastMessageTime:string,
}

export interface listadoPerfilPublicoInterface {
    displayName:string,
    email:string,
    uid:string,
    photoURL:string
}

export interface authInterface {
    displayName?:string,
    photoURL?:string,
    uTipo?:string,
}
export interface RedireccionarInterface {
    redireccionar:string
}
export interface loginEmailInterface {
    email:string,
    pass:string,
    redireccionar?:string,
    nombre?:string,
    displayName?:string
}
export interface emailInterface {
    email:string,
    redireccionar?:string
}

export class infoPerfilInterface {
    nombre?:string
    email?:string
    uid?:string
    phoneNumber?:string
    photoURL?:string
    displayName?:string
    emailVerified?:boolean
    eid?:string
    uTipo?:string
    eCard?:string
    eCardHand?:string
    constructor(r:any){
        this.nombre=r.nombre||'';
        this.email=r.email ||'';
        this.uid=r.uid ||'';
        this.phoneNumber=r.phoneNumber ||'';
        this.photoURL=r.photoURL ||'';
        this.displayName=r.displayName ||'';
        this.emailVerified=r.emailVerified ||false;
        this.eid=r.eid ||'';
        this.uTipo=r.uTipo ||'';
        this.eCard=(r.eCard!=undefined&&r.eCard!='')?r.eCard: FuseUtils.generateGUID(); FuseUtils.handleize
        this.eCardHand=FuseUtils.handleize(this.displayName);      
        
    }
}
export interface navInterface {
    app?:{
        nombre:string,
        urls?:{
            sitioWeb?:string,
            terminosYCondiciones?:string,
            avisoDePrivacidad?:string
        }
    },
    cliente?:{
        projectId?:string
        logo?:string
        cargaArchivos?:string
        tema?:object
    },
    usuario?:{
        photoProfile?:string
    }
}
export interface perfilTimeLineInterface{
    activities: [
        {
            user   : {
                name  :string,
                avatar:string
            },
            message:string,
            time   :string
        },
       
    ],
    posts     : [ {
        user    : {
            name  :string,
            avatar:string,
        },
        message :string,
        time    :string,
        type    :string,
        like    :string,
        share   :string,
        media   : {
            type   :string,
            preview:string
        },
        comments: [
            {
                user   : {
                    name  :string,
                    avatar:string,
                },
                time   : string,
                message: string,
            }
        ]
    }]
}
export interface perfilMediaInterface{
  media:[{
            name :string,
            info :string,
            media: [
                {
                    type   :string,
                    title  :string,
                    preview:string,
                    url:string
                }
            ]
        }
    ]
}
export interface perfilAcercaInterface{
    general?: {
        gender?:string,
        gender_p?:boolean,
        birthday?: string,
        birthday_p?:boolean,
        locations?: Array<string>,
        locations_p?:boolean,
        about?:string
        about_p?:boolean
    },
    work?: {
        occupation?:string,
        occupation_p?:boolean,
        skills?:Array<pefilAcercaSkilsInterface>,
        skills_p?:boolean,
        jobs?:Array<pefilAcercaJobsInterface>
        jobs_p?:boolean,

    },
    contact?: {
        address ?:string,
        address_p?:boolean,
        tel?:Array<string>,
        tel_p?:boolean,
        websites?: Array<string>,
        websites_p?:boolean,
        emails  ?: Array<string>
        emails_p?:boolean,

    },
    social?:Array<pefilSocialInterface>,
    social_p?:boolean,
    groups ?: Array<pefilGroupsInterface>,
    groups_p?:boolean,
    friends?: Array<pefilFriendsInterface>
    friends_p?:boolean
}

export interface pefilSocialInterface{
        provider:string,
        url:string
}
export interface pefilAcercaSkilsInterface{
        name:string,
        percent:string,
        rating?:string,
        userRating?:Array<string>
}
export interface pefilAcercaJobsInterface{
        company:string,
        date:string
}
export interface pefilGroupsInterface{
    id    :string,
    name    :string,
    category:string,
    members :Array<infoPerfilInterface>
}
export interface pefilFriendsInterface{
    id  :string,
    name  :string,
    avatar:string
    info :infoPerfilInterface
}

export interface pefilPublicoInterface{
    general:Array<any>,
    work:Array<any>,
    contact:Array<any>,
    social:string,
    groups:string,
    friends:string
}

export class selectInterface{
    value:string
    name:string
    constructor(d){
        d=d||{}
        this.value=d.value||''
        this.name=d.name||''
    }
}
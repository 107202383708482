import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { ContactsEcardComponent } from 'app/main/apps/contacts/ecard/ecard.component';
import { ContactsService } from 'app/main/apps/contacts/contacts.service';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
@NgModule({
    declarations: [
        ToolbarComponent,
        ContactsEcardComponent
    ],    
    providers      : [
        ContactsService
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatChipsModule,
        MatCardModule,
        FuseSharedModule,
        FuseSearchBarModule,
        MatCheckboxModule,
        MatRippleModule,
        MatDatepickerModule,
        MatInputModule,
        MatTableModule,
        MatListModule,
        MatGridListModule,
        MatButtonToggleModule,
        MatFormFieldModule,
        FuseShortcutsModule
    ],
    exports     : [
        ToolbarComponent
    ], entryComponents: [
        ContactsEcardComponent
    ]
})
export class ToolbarModule
{ 
}

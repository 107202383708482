import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { JmyArchivosComponent,SafePipe } from '../jmy/jmy-archivos/jmy-archivos.component';
import { JmyTimelineComponent } from '../jmy/jmy-timeline/jmy-timeline.component';
import { JMYTimeLineService } from '../jmy/jmy-timeline/jmy-timeline.service';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule  } from '@angular/material/radio';
import { MatCheckboxModule  } from '@angular/material/checkbox';
import { MatCardModule  } from '@angular/material/card';
import { MatListModule  } from '@angular/material/list';
import { MatGridListModule  } from '@angular/material/grid-list';
//import { JmyRoutes } from './jmy.routing';
//import { JmyWebModule } from './jmy-web/jmy-web.module';
import { from } from 'rxjs';
//export { JmyWeb } from './jmy-web/jmy-web.module';
/*
import { RegisterComponent } from './register/register.component';
import { LockComponent } from './lock/lock.component';
import { LoginComponent } from './login/login.component';
*/

@NgModule({
    imports: [
       // RouterModule.forChild(JmyRoutes),
      MatButtonModule,
      MatDividerModule,
      MatIconModule,
      MatTabsModule,
      MatProgressSpinnerModule,
      FuseSharedModule,
      MatFormFieldModule,
      MatInputModule,
      MatSelectModule,
      MatDatepickerModule,
      MatRadioModule,
      MatCheckboxModule,
      MatCardModule,
      MatListModule,
      MatGridListModule
    ],
    exports:[
      JmyArchivosComponent,
      JmyTimelineComponent
    ], providers   : [
      JMYTimeLineService
  ],
    declarations: [
      JmyArchivosComponent,
      SafePipe,
      JmyTimelineComponent,
  //  JmyWebComponent
  ]
})
export  class JmyModule {}

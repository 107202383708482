
import { DbStatesInterface } from "../interfaces/services";
export class States
{
    public static states = {
      no_save:'no_save', 
      save:'save', 
      new:'new',
      cache:'cache',
      private:'private',
      public:'public',
      group:'group',
      db:'firebase',
      table:'GENERAL'
    };
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';

import { Contact } from 'app/main/apps/contacts/contact.model';
import { JmyService } from '../../../concomsis/jmy/jmy.service';
import { ValidateEmail,ValidateAt } from '../../../concomsis/jmy/utilidades';
import { ContactosListMinInterface , infoPerfilInterface } from '../../../concomsis/jmy/interfaces/services';
import { FormGroup } from '@angular/forms';

@Injectable()
export class ContactsService implements Resolve<any>
{
    onContactsChanged: BehaviorSubject<any>;
    onSelectedContactsChanged: BehaviorSubject<any>;
    onUserDataChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;
    t:any;
    contacts: Contact[];
    newContact:  BehaviorSubject<ContactosListMinInterface[]>;
    user: any;
    selectedContacts: string[] = [];

    searchText: string;
    filterBy: string;
    validateEmail=ValidateEmail;
    validateAt=ValidateAt;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private jmyService: JmyService
    )
    {
        // Set the defaults
        this.onContactsChanged = new BehaviorSubject([]);
        this.onSelectedContactsChanged = new BehaviorSubject([]);
        this.onUserDataChanged = new BehaviorSubject([]);
        this.newContact = new BehaviorSubject([]);
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
        this.contacts =[]
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getContacts(),
                this.getUserData()
            ]).then(
                ([files]) => {

                    this.onSearchTextChanged.subscribe(searchText => {
                        this.searchText = searchText;
                        this.getContacts();
                    });

                    this.onFilterChanged.subscribe(filter => {
                        this.filterBy = filter;
                        this.getContacts();
                    });

                    resolve();

                },
                reject
            );
        });
    }
    public saveNewContact(d:any){
        console.log(this.jmyService.jmyPerfil.rutaPrivada);
        
        this.jmyService.dbRealTime.object(this.jmyService.jmyPerfil.rutaPrivada+'contactos/lista/'+d.id).set(d).then(()=>{
            console.log('Nuevo contacto agregado',this.searchText);
            this.searchText='';
        });
    }
    public guardarECard(d:infoPerfilInterface){
        console.log(d,this.jmyService.jmyPerfil.rutaPrivada,this.jmyService.jmyPerfil.perfil);
        if(this.jmyService.jmyPerfil.perfil.uid!=''){
            this.jmyService.jmyPerfil.actualizarPerfil(d).then(r=>{
                console.log('eCard Actualizado',d,r);
            }).catch(e=>{
                console.error(e);
                
            })
        }else{
            console.error('no hay sessión');
        }
    }
    public getNewContact(): any {
        const studentsObservable = new Observable(observer => {
               setTimeout(() => {
                   console.log(this.newContact);
                   
                   observer.next(this.newContact);
               }, 1000);
        });
   
        return studentsObservable;
    }
    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */
    getContacts(): Promise<any>
    {
        return new Promise((resolve, reject) => {
                //this._httpClient.get('api/contacts-contacts')
                let tBus:string='';
                console.log(this.jmyService.jmyPerfil.rutaPrivada+'contactos/lista/');
                
                    const contactos:any = this.jmyService.dbRealTime.object(this.jmyService.jmyPerfil.rutaPrivada+'contactos/lista/').valueChanges();
                    contactos.subscribe(response=>{
                        const fn=(rdb:any)=>{
                            const t:any=[];
                            if(typeof rdb=="object")
                              Object.keys(rdb).forEach(e => {
                                t.push(rdb[e]);
                              });
                            return t;
                          }
                        this.contacts  =(response!=undefined)?fn(response):this.contacts;
                        console.log(this.contacts,response );

                        if ( this.filterBy === 'starred' )
                        {
                            this.contacts = this.contacts.filter(_contact => {
                                return this.user.starred.includes(_contact.id);
                            });
                        }

                        if ( this.filterBy === 'frequent' )
                        {
                            this.contacts = this.contacts.filter(_contact => {
                                return this.user.frequentContacts.includes(_contact.id);
                            });
                        }

                        if ( this.searchText && this.searchText !== '' )
                        {
                            
                            this.contacts = FuseUtils.filterArrayByString(this.contacts, this.searchText);
                            console.log(this.searchText);
                            if(this.searchText.length>4){
                                tBus=(this.validateAt(this.searchText))?'email':'displayName';
                                this.jmyService.dbRealTime.list(this.jmyService.jmyPerfil.rutaPublica+'lista/',ref=>ref.orderByChild(tBus).startAt(this.searchText).endAt(this.searchText+'\uf8ff')).valueChanges().subscribe(r=>{
                                console.log(r);
                                
                                    let tm=[];
                                    r.forEach(e => {
                                    tm.push({
                                            photoURL:e['photoURL'],
                                            email:e['email'],
                                            uid:e['uid'],
                                            displayName:e['displayName'],
                                        });
                                    });
                                    this.newContact.next(tm);
                                    console.log(r,this.newContact );
                                    
                                });
                            }
                        }else{
                            this.newContact.next([]);
                        }

                        this.contacts = this.contacts.map(contact => {
                            return new Contact(contact);
                        });
                        
                        this.onContactsChanged.next(this.contacts);
                        resolve(this.contacts);
                    }, reject);
            }
                
              
        );
    }

    /**
     * Get user data
     *
     * @returns {Promise<any>}
     */
    getUserData(): Promise<any>
    {
        return new Promise((resolve, reject) => {
                this._httpClient.get('api/contacts-user/5725a6802d10e277a0f35724')
                    .subscribe((response: any) => {
                        this.user = response;
                        this.onUserDataChanged.next(this.user);
                        resolve(this.user);
                    }, reject);
            }
        );
    }

    /**
     * Toggle selected contact by id
     *
     * @param id
     */
    toggleSelectedContact(id): void
    {
        // First, check if we already have that contact as selected...
        if ( this.selectedContacts.length > 0 )
        {
            const index = this.selectedContacts.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedContacts.splice(index, 1);

                // Trigger the next event
                this.onSelectedContactsChanged.next(this.selectedContacts);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedContacts.push(id);

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void
    {
        if ( this.selectedContacts.length > 0 )
        {
            this.deselectContacts();
        }
        else
        {
            this.selectContacts();
        }
    }

    /**
     * Select contacts
     *
     * @param filterParameter
     * @param filterValue
     */
    selectContacts(filterParameter?, filterValue?): void
    {
        this.selectedContacts = [];

        // If there is no filter, select all contacts
        if ( filterParameter === undefined || filterValue === undefined )
        {
            this.selectedContacts = [];
            this.contacts.map(contact => {
                this.selectedContacts.push(contact.id);
            });
        }

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
     * Update contact
     *
     * @param contact
     * @returns {Promise<any>}
     */
    updateContact(contact): Promise<any>
    {
        return new Promise((resolve, reject) => {
            console.log(contact);
            const q=this.jmyService.dbRealTime.object(this.jmyService.jmyPerfil.rutaPrivada+'contactos/lista/'+contact.id)
            q.set(contact).then(()=>{
                console.log('Contacto Actualizado');                
            });

            this.getContacts();
            resolve();

        });
    }

    /**
     * Update user data
     *
     * @param userData
     * @returns {Promise<any>}
     */
    updateUserData(userData): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post('api/contacts-user/' + this.user.id, {...userData})
                .subscribe(response => {
                    this.getUserData();
                    this.getContacts();
                    resolve(response);
                });
        });
    }

    /**
     * Deselect contacts
     */
    deselectContacts(): void
    {
        this.selectedContacts = [];

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
     * Delete contact
     *
     * @param contact
     */
    deleteContact(contact): void
    {
        console.log(contact);
        
        const contactIndex = this.contacts.indexOf(contact);
        this.contacts.splice(contactIndex, 1);
        this.onContactsChanged.next(this.contacts);

        this.jmyService.dbRealTime.object(this.jmyService.jmyPerfil.rutaPrivada+'contactos/lista/'+contact.id).remove().then(()=>{  console.log('del',contact.id);
       });
    }

    /**
     * Delete selected contacts
     */
    deleteSelectedContacts(): void
    {
        for ( const contactId of this.selectedContacts )
        {
          
            this.jmyService.dbRealTime.object(this.jmyService.jmyPerfil.rutaPrivada+'contactos/lista/'+contactId).remove().then(()=>{  console.log('del',contactId);
            });
            const contact = this.contacts.find(_contact => {
                return _contact.id === contactId;
            });
            const contactIndex = this.contacts.indexOf(contact);
            this.contacts.splice(contactIndex, 1);
        }
        this.onContactsChanged.next(this.contacts);
        this.deselectContacts();
    }

}

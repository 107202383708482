import { NgModule ,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent  } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFireStorageModule } from '@angular/fire/storage'

import { UserResolver } from './concomsis/user/user.resolver';
import { AuthGuard } from './concomsis/core/auth.guard';
import { JmyService } from './concomsis/jmy/jmy.service';
import { JmyPerfil } from "./concomsis/jmy/admin/JmyPerfil";
import { JmyFn } from "./concomsis/jmy/admin/jmyFn";
import { JmyS } from "./concomsis/jmy/admin/jmyS";
//import { JmyDB } from "./concomsis/jmy/admin/jmyDB";

import { UserService } from './concomsis/core/user.service';
import { JmyModule } from './concomsis/jmy/jmy.module';
import { environment } from 'environments/environment';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
 
import { ToastrModule } from 'ngx-toastr';
import { JmyLogin } from './concomsis/jmy/admin/JmyLogin';


const appRoutes:Routes=[
    {
        path        : 'tpk',
        loadChildren: './main/apps/tpk/tpk.module#TpkModule'
    },
    {
        path        : 'almacen',
        loadChildren: './main/apps/almacen/almacen.module#AlmacenModule'
    },
    {
        path        : 'escuela',
        loadChildren: './main/apps/escuela/escuela.module#EscuelaModule'
    },
    {
        path        : 'seminuevos',
        loadChildren: './main/apps/seminuevos/seminuevos.module#SeminuevosModule'
    },
    {
        path        : 'jmypro',
        loadChildren: './main/apps/jmypro/jmypro.module#JmyproModule'
    },
    {
        path        : 'apps',
        loadChildren: './main/apps/apps.module#AppsModule'
    },
    {
        path        : 'pages',
        loadChildren: './main/pages/pages.module#PagesModule' 
    },
    {
        path        : 'a',
        loadChildren: './main/pages/auth.module#AuthModule'
    },
    {
        path      : '404',
        redirectTo: 'pages/errors/error-404'
    },
    {
        path      : '**',
        redirectTo: 'apps/dashboards/jmydashboard'
    }
];

@NgModule({  
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay             : 0,
            passThruUnknownUrl: true
        }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireDatabaseModule,
        // Material moment date module 
        MatMomentDateModule,
        JmyModule,
        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        AngularFontAwesomeModule,
        AngularFireStorageModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        // App modules
        LayoutModule,
        AppStoreModule,
        
         
        //JmyModule
    ],
    providers: [AngularFireAuth,JmyService, JmyLogin, JmyPerfil,JmyFn, JmyS , UserService, UserResolver, AuthGuard],
    bootstrap   : [
        AppComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class AppModule
{
}
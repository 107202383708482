export function ValidateEmail(mail:string){
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
        return (true)

    return (false)
}
export function ValidateAt(mail:string){
    if (/^\w+([\.-]?\w+)*@/.test(mail))
        return (true)

    return (false)
}

export function sumar (va:number,vb:number):number { 
        return va+vb;
    }

    

export function restar (va:number,vb:number):number { 
    return (va>vb)?va-vb:vb-va;
}
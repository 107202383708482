import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { jmyConfig } from 'environments/environment';
import { Observable,BehaviorSubject, from, Subject } from 'rxjs';
import { navInterface, 
        infoPerfilInterface,
        perfilAcercaInterface,
        pefilPublicoInterface,
        listadoPerfilPublicoInterface
    } from '../interfaces/services';
import { States } from '../admin/states';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { navigation } from 'app/navigation/navigation';
import { log } from 'util';
import { Router }  from '@angular/router'
import { MatSnackBar } from '@angular/material/snack-bar';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
@Injectable()
export class JmyPerfil {
  private config: navInterface = jmyConfig;
  private usersRef: AngularFireList<any>; // Reference to users list, Its an Observable
  private userRef: AngularFireObject<any>; // Reference to user object, Its an Observable too
  public rutaPrivada: string;
  public rutaPrivadaRoot: string;
  public rutaPublica: string;
  public rutaPublicaUsuario: string;
  public rutaPublicaAmigos: string;
  private rdba: string;
  private rdbu: string;
  private rdbp: string;
  private rdbpL: string;
  private items: Observable<any[]>;
  private permisos:any={};
  public permisosPeriles: BehaviorSubject<any>;
  public perfilEmpresa:any;
  public perfil: infoPerfilInterface={uid:null,email:null};
  public perfilChange: BehaviorSubject<infoPerfilInterface>;
  public uid:string;
  public acerca:perfilAcercaInterface;
  navigation:any;
  verPerfil:Observable<any[]>
  editarPermiso:any;
  permisoSesion:object;
  private cOpG:any;
  private cOpGP:any;
  
  private _unsubscribeAll: Subject<any>;
  constructor(
      private db: AngularFireDatabase,
      private http: HttpClient,
      private router: Router,
      private fuseNavigationService: FuseNavigationService,

      private _matSnackBar: MatSnackBar,
      ) {
        this.permisosPeriles = new BehaviorSubject({});       
         /* 
          this.permisosPeriles.subscribe(r=>{
              console.log(r);
          })*/
        this.perfilEmpresa={};
        const d:infoPerfilInterface =  JSON.parse(localStorage.getItem('jmy_AcP')) || {};   
        this.perfil=d;
        this.perfilChange = new BehaviorSubject(this.perfil);
        this.cargaPerfil(d).then(r=>{
            this.perfil=r;
            this.perfilChange.next(this.perfil);
        });

        if(d!=undefined){ 
            this.uid=d.uid;
            this.cargaRutas(this.uid);
            this.navigation=navigation;
//            console.log(this.uid,this.rdbu);
            if(this.uid!=''){
                const vPT=this.db.object(this.rdbu).valueChanges();
                vPT.subscribe(v=>{
                    //console.log('vPT',v);
                    if(v==undefined){
                        this.crearPerfil().then(rC=>{
                        });
                    }else{
                        this.perfil=v['perfil'] || [];
                        this.perfilChange.next(this.perfil);
                    }
                })
                this.acerca ={
                    general: {
                        gender: null,
                        gender_p:false,
                        birthday: 'dd/mm/aaaa',
                        birthday_p:false,
                        locations: ['México'],
                        locations_p:false,
                        about:null,
                        about_p:true,
                    },
                    work  : {
                        occupation:null,
                        occupation_p:true,
                        skills:[],
                        skills_p:false,
                        jobs:[],
                        jobs_p:false
                    },
                    contact: {
                        address :'---',
                        address_p:false,
                        tel:['000-000-000'],
                        tel_p:false,
                        websites: [location.origin+'/publico/perfil/'+this.uid+'/'],
                        websites_p:false,
                        emails  : [this.perfil.email],
                        emails_p:true
                    },
                    social : [],
                    social_p:true,
                    groups : [],
                    groups_p:false,
                    friends: [],
                    friends_p:false
                }
            }
        }
        //this.permisoSesion = this.verPermiso({uid:this.perfil.uid});
        //console.log(this.permisoSesion );
        if(this.perfil!=undefined)
            this.verPermiso({uid:this.perfil.uid}).then(cP=>{
            })
        else
            this.router.navigate(['/a/auth/login']);

            
        this._unsubscribeAll = new Subject();
  }
  miPermiso(d:any){
    return (this.perfil==undefined)?null:((typeof d[this.perfil.uid]!="undefined")?d[this.perfil.uid]:null);
  }
  getApiJmy() {
    return this.http.get(jmyConfig.app.servidor).subscribe(r=>{
      //  console.log(r);
    });
  }
  datosEmpresa() : Promise<object> {
      return new Promise((res,rej)=>{
        const dET:any=[];
        const k = 'dEt'+jmyConfig.app.eid;
        if(!Object.keys(dET).includes(k))
            this.http.post(jmyConfig.app.servidor+'empresa/perfil',{
                idEmpresa:jmyConfig.app.eid,
                key:jmyConfig.app.key
            }).pipe(takeUntil(this._unsubscribeAll)).subscribe(r=>{
                  console.log(r);
                    this.perfilEmpresa=r;                    
                    localStorage.setItem(k,JSON.stringify({...this.perfilEmpresa,key:'xxx',token:'xxx'}));
                    res(r) 
            });
        else{
            console.log(dET);
            res(dET[k])
            }
      })
  }

  verPermiso(d:{
    uid?:string,
    cuid?:string,
    api?:string,
    minimoAcceso?:number,
    maximoAcceso?:number,
  }={uid:null,cuid:null}) {
      return new Promise((res,rej)=>{
        
        d.uid = (this.perfil!=undefined)?this.perfil.uid:null;
        d.uid=(d.uid==undefined && this.perfil!=undefined)?this.perfil.uid:d.uid;
        
        d.cuid = (d.cuid!=undefined)?d.cuid:d.uid;        
        if(typeof this.permisos[d.cuid]=="undefined" && d.uid!=undefined ){
            console.log(jmyConfig.app.servidor);            
            const cOpGP=this.http.post(jmyConfig.app.servidor+'usuario/permiso',{
                idEmpresa:jmyConfig.app.eid,
                key:jmyConfig.app.key,
                uid:d.uid,
                cuid:d.cuid
            })
            .subscribe(r=>{
                cOpGP.unsubscribe();
                console.log(r,jmyConfig.app.servidor+'usuario/permiso',{
                    idEmpresa:jmyConfig.app.eid,
                    key:jmyConfig.app.key,
                    uid:d.uid,
                    cuid:d.cuid
                });
                let t:any = r || {};
                this.permisos[d.cuid]=t;
                this.permisosPeriles.next(this.permisos);
                res((d.api!=undefined)?t[d.api]:t);
            })
        }else
            if(d.api!=undefined){
                let t:any = this.permisos[d.cuid];
                res(t[d.api]);
            }else{
                res(this.permisos[d.cuid])
            }
            
      })
  }
  guardarPermisoQ:any;
  guardarPermiso(d:{
    permiso:string,
    app:string,
    uid:string,
    cuid?:string,
  }) {
      let q:any;
    return new Promise((res,rej)=>{
        //console.log(this.perfilEmpresa['config']['modulos_definido'] );
            //this.guardarPermisoQ=this.permisosPeriles.subscribe(r=>{
                //this.permisosPeriles.unsubscribe();
          //  console.log(r[this.perfil.uid]);
            this.permisos[this.perfil.uid]=Object.assign(this.perfilEmpresa['config']['modulos_definido'],this.permisos[this.perfil.uid]||{});
            if(d.permiso!=undefined)
            this.permisos[this.perfil.uid][d.app]=Number(d.permiso)
            this.permisosPeriles.next(this.permisos);
            this.cOpG = this.http.post(jmyConfig.app.servidor+'usuario/permiso/guardar',{
                idEmpresa:jmyConfig.app.eid,
                key:jmyConfig.app.key,
                app:d.app,
                uid:d.uid,
                cuid:d.cuid,
                permisos:this.permisos[this.perfil.uid],
            })
            .subscribe(r=>{              
                this.cOpG.unsubscribe();
                res(r) 
            });
       // })
  })
}
  navegacion(nV?:any){
      return new Promise((res,rej)=>{
        const fn:any = {
            isT:(per:{
                permiso:number;
                modulo:string;
            }[],perU:object):boolean=>{
                let o:boolean=false
                if(typeof per =='object')
                    per.forEach(e => {
                        if(perU!= undefined)
                        if(typeof perU[e.modulo] == "number")
                            o=(perU[e.modulo] >= e.permiso)?true:o;
                    });
                return o;
            },
            nC:(n:Array<object>,r:object)=>{  
                let oN:Array<any> = [];
                if(typeof n =="object")
                    n.forEach(e => {
                        const mo:{
                            permiso:number;
                            modulo:string;
                        }[]=e['modulo'];
                        if(fn.isT(mo,r)){
                            if(typeof e['children'] =="object")
                                e['children']=fn.nC(e['children'],r);
                            this.fuseNavigationService.updateNavigationItem(e['id'],{
                                hidden:false
                            })
                            oN.push(e);
                        }                     
                    });
                return oN;              
            }
        };        
        this.permisosPeriles.subscribe(r=>{
            console.log('permisos',r[this.perfil.uid],fn.nC(this.navigation,r));
           // this.fuseNavigationService.setCurrentNavigation()
            res(fn.nC(this.navigation,r[this.perfil.uid]));
        });
      })
  }
  agregarAmigo(uid:string){
      return (uid==undefined)?null:new Promise((res,rej)=>{        
          
        if(this.uid!=''){    
          this.db.object(this.rdbpL+'/lista/'+uid).valueChanges().subscribe(r=>{
            const n=this.db.object(this.rdba+'/'+uid);
            if(r)
                n.set(r).then(gr=>{
                    res(r);
                }).catch(err=>{
                    console.error(err);
                    rej(err)
                });
            else{
                console.error('No existe el usuario');
                   // Show the success message
              
                rej('No existe el usuario');
            }
          });
        }else
        rej('No hay sesión');
      })
  }
  borrarAmigo(uid:string){
      return (uid==undefined)?null:new Promise((res,rej)=>{    
        if(this.uid!=''){          
          this.db.object(this.rdbpL+'/lista/'+uid).valueChanges().subscribe(r=>{
              const n=this.db.object(this.rdba+'/'+uid);
                if(r)
                    n.remove().then(gr=>{
                        res(r);
                    }).catch(err=>{
                        console.error(err);
                        rej(err)
                    });
                else{
                    console.error('No existe el usuario');
                    rej('No existe el usuario');
                }
          }); 
        }else
          rej('No hay sesión');
      })
  }
  actualizarPerfil(d:infoPerfilInterface){
      return new Promise((res,rej)=>{
        this.perfilPrivado(d).then(r=>{
            this.perfil=new infoPerfilInterface(d);
            console.log(d,this.perfil);
            this.perfilChange.next(this.perfil);
         //   console.log(' actualizarPerfil 👌',this.perfil);
            res(this.perfil)
        }).catch(r=>{
            console.error(r,this.acerca);
            rej(r)
        });
    });
  }
  private cargaRutas(uid:string,ver:boolean=false){
    if(!ver){
        this.rutaPrivada=States.states.private+ '/perfiles/'+uid+'/';
        this.rutaPrivadaRoot=States.states.private+ '/perfiles/';
        this.rutaPublica=States.states.public+'/perfiles/';
        this.rutaPublicaUsuario=States.states.public+'/perfiles/'+uid+'/';
        this.rdbp=States.states.private+'/perfiles/'+uid+'/perfil/';
        this.rdbpL=States.states.public+'/perfiles/';
        this.rdbu=States.states.private+'/perfiles/'+uid+'/perfil/';
        this.rdba=States.states.public+'/perfiles/'+uid+'/amigos/';
        this.rutaPublicaAmigos=this.rdba;  
    }

    return {
        rutaPrivada:States.states.private+ '/perfiles/'+uid+'/',
        rutaPrivadaRoot:States.states.private+ '/perfiles/',
        rutaPublica:States.states.public+'/perfiles/',
        rutaPublicaUsuario:States.states.public+'/perfiles/'+uid+'/',
        rdbp:States.states.private+'/perfiles/'+uid+'/perfil/',
        rdbpL:States.states.public+'/perfiles/',
        rdbu:States.states.private+'/perfiles/'+uid+'/perfil/',
        rdba:States.states.public+'/perfiles/'+uid+'/amigos/',
        rutaPublicaAmigos:this.rdba,
        uid:uid
    }
  }
  cargaPerfil(d:infoPerfilInterface=new infoPerfilInterface({})):Promise <infoPerfilInterface>{

    return new Promise((res, rej) => {
//        if(this.perfil!=)
        if(d.uid==undefined  && d.uid=='' )
            d.uid = this.perfil.uid;
            
            
            const ru = this.cargaRutas(d.uid,((this.perfil.uid!=d.uid)?true:false));
//          console.log(d,this.perfil,new infoPerfilInterface(d),ru);
        if(ru.uid!=undefined &&ru.uid!=''){
            const q = this.db.object(ru.rutaPrivada+'perfil')
            
            const qS = q.valueChanges().subscribe(rqS=>{
                this.perfil={...rqS,...d};
                qS.unsubscribe();
                q.set(this.perfil).then(r=>{
//                    console.log(d,ru,this.perfil);
                    if(this.perfil.uid==d.uid||this.perfil.uid==''){
                        this.perfil=d;
                        localStorage.setItem('jmy_AcP',JSON.stringify(this.perfil));
                        this.perfilChange.next(this.perfil);
                        
                        res(this.perfil)
                    }
                })
            })
        }else
            res( new infoPerfilInterface({}))
    });
}

  private crearPerfil(){
    return new Promise((res,rej)=>{
       
        this.perfilPrivado(new infoPerfilInterface(this.perfil)).then(r=>{
            res(r);
        }).catch(r=>{
            rej(r);
        });
    });

  }
  private perfilPrivado(d:infoPerfilInterface){
      return new Promise ((res,rej)=>{
       console.log(this.rdbu,d,this.perfil,this.rutaPublica+'lista/'+this.perfil.uid);          
        this.perfil={...this.perfil,...d};
        this.db.object(this.rdbu).set(this.perfil).then(r => {
        })
        this.db.object(this.rutaPublica+'lista/'+this.perfil.uid).set(this.perfil).then(rb => {        
            res
        }) 
      })
  }
  private acercaPublico(d:perfilAcercaInterface,b:pefilPublicoInterface={ general:[],
    work:[],contact:[],social:'',groups:'',friends:''}){
        return new Promise ((res,rej)=>{
            let o = {};
            Object.keys(b).forEach(e=>{
                if(typeof b[e] == 'string'){ 
                    if(typeof d[e] != 'boolean')
                        if(d[e+'_p'])
                            o[e]=d[e]
                }else{
                    o[e]={};
                    Object.keys(d[e]).forEach(p => {
                        if(typeof d[e][p] != 'boolean')
                            if(d[e][p+'_p'])
                                o[e][p]=d[e][p]
                    });
                }
            });    
            const itemRefP = this.db.object(this.rdbp);
            itemRefP.set({
                acerca:o,
                perfil:{
                    displayName:this.perfil.displayName
                }
            }).then(rp => {
               // console.log('perfilPublico 👌', rp);
               /* this.listadoPerfilPublico({
                    displayName:this.perfil.displayName,
                    email:this.perfil.email,
                    uid:this.perfil.uid,
                    photoURL:this.perfil.photoURL
                }).then(rP=>{
                    res(true);
                }).catch(err=>{
                    rej(err)
                })*/
            }).catch(rp => {
                rej(rp)
                console.log('catch', rp);
            });
        })
  }
  verPerfilPublico(d:listadoPerfilPublicoInterface){
      return new Promise((res,rej)=>{
        const itemRefP = this.db.object(this.rdbpL+'/lista/'+this.uid);
        itemRefP.set(d).then(rp => {
            console.log('listadoPerfilPublico 👌', d);
            res
        }).catch(rp => {
             console.log('catch', rp);
             rej
        });
      })

  }
  listaPerfilesPublico(d:listadoPerfilPublicoInterface){
      return new Promise((res,rej)=>{
        const itemRefP = this.db.object(this.rdbpL+'/lista/'+this.uid);
        itemRefP.set(d).then(rp => {
            console.log('listadoPerfilPublico 👌', d);
            res
        }).catch(rp => {
             console.log('catch', rp);
             rej
        });
      })

  }
  listadoPerfilPublico(d:listadoPerfilPublicoInterface){
      return new Promise((res,rej)=>{
        const itemRefP = this.db.object(this.rdbpL+'/lista/'+this.uid);
        itemRefP.set(d).then(rp => {
            console.log('listadoPerfilPublico 👌', d);
            res
        }).catch(rp => {
            console.log('catch', rp);
            rej
        });
      })

  }

  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }



}

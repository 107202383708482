export const locale = {
    lang: 'es',
    data: {
        'NAV': {
            'APPS': 'Aplicaciones',
            'APPLICATIONS': 'Aplicaciones',
            'APPLICATIONSALPHA': 'Aplicaciones ALPHA',
            'DASHBOARDS'  : 'Dashboards',
            'CALENDAR'    : 'Calendario',
            'ECOMMERCE'   : 'Tineda virtual',
            'ACADEMY'     : 'Academia',
            'MAIL'        : {
                'TITLE': 'Mail',
                'BADGE': '25'
            },
            'MAIL_NGRX'        : {
                'TITLE': 'Mail Ngrx',
                'BADGE': '13'
            },
            'CHAT'        : 'Chat',
            'FILE_MANAGER': 'Archivos',
            'CONTACTS'    : 'Contactos',
            'TODO'        : 'Tareas',
            'SCRUMBOARD'  : 'Scrum Board',
            'TECNOPACK'  : 'Tecnopack',
            'DISENO'  : 'Diseño',
            'VENTAS'  : 'Ventas',
            'COTIZACIONES'  : 'Cotizaciones',
            'COMPRAS'  : 'Compras',
            'CLIENTES'  : 'Clientes',
            'ALMACEN'  : 'Almacén',
            'ALMACENPRODUCTOS'  : 'Productos',
            'SEMINUEVOS'  : 'Seminuevos',
            'SEMINUEVOSUNIDADES'  : 'Unidades',
            'ENCUESTAS'  : 'Encuestas',
            'ENCUENSTASDISPONIBLES'  : 'Encuestas disponibles',
            'ENCUENSTASREALIZADAS'  : 'Encuestas realizadas',
            'CURSOSDISPONIBLES':'Cursos disponibles',
            'CURSOSTERMINADOS':'Cursos terminados',
            'CURSOSEVALUACIONES':'Evaluaciones',
            'INICIO':'Inicio',
            'PROSPECTOS_Y_CLIENTES':'Prospectos y clientes',
            'PROSPECTOS_Y_CLIENTES_DIRECTORIO':'Directorio',
            'PROSPECTOS_Y_CLIENTES_CALENDARIO':'Calendario',
            'SEGUIMIENTO_PROYECTOS_LISTA':'Proyectos',
            'SEGUIMIENTO_PROYECTOS':'Proyectos'
            
        },
        'PERFIL':{
                'TIMELINE':'Linea del tiempo'
        }        
    }
};
